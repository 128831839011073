import Footer from "../Footer";
import Header from "../Header";
import Rubrik from "../Rubrik";
import UpArrow from "../UpArrow";
import GFurerPortrait from "../../images/gfurer/gfurer-portrait.jpg";
function FurerG(props) {
    return (
        <div id="Wrapper" className="container_14">

            <div id="Header">
                <Header id={props.author} />
                <div className="grid_9">
                    <h1 id="main_title">Plurilinguisme programmé/volontaire/voulu</h1>
                    <p id="author">Gianmarco Furer, San Vittore GR/Schweiz</p>
                </div>
                <Rubrik />
            </div>

            <div id="Teasers" className="clearfix">
                <div className="grid_9">
                    <h1>Remarques préliminaires</h1>
                    <p>
                        Le présent article n’est pas une étude ou un rapport scientifiques,
                        il est un témoignage personnel sur une expérience de vie multilingue.
                        Comme mon frère a partagé la même expérience, je l’associe
                        largement à ce témoignage en donnant également les différences,
                        plutôt de détail, dans la façon dont nous avons vécu et vivons notre
                        expérience commune.
                    </p>
                    <p>
                        Je me refuse à considérer mon expérience comme exceptionnelle, même
                        si je suis conscient qu’elle n’est pas très commune non plus.
                        Surtout, je crois que mon témoignage peut être utile pour que des
                        entreprises semblables se multiplient – et s’améliorent. Je suis en
                        effet convaincu, par cette expérience même, qu’un large
                        plurilinguisme individuel est possible, et représente une immense
                        richesse non seulement pour l’individu, mais également pour la
                        société. Je m’oppose en tout cas de toutes mes forces à l’idée
                        encore largement colportée selon laquelle le plurilinguisme et même le
                        simple bilinguisme sont nuisibles au développement harmonieux de
                        l’enfant. Comme mon frère placé dans la même situation, je me sens
                        tout simplement bien dans mes différentes langues, même si je les
                        maîtrise à des niveaux divers, et je ne suis ni un génie
                        linguistique, ni un animal de cirque.
                    </p>
                </div>
                <div className="grid_5">

                    <div className="abouttheauthor">
                        <img src={GFurerPortrait} width="100"
                            height="138" alt="Gianmarco Furer - Portrait" />
                        <div className="abouttheauthorfont"><br /><br />
                            <b>Gianmarco Furer</b>, Student der Wirtschaft an der Università
                            della Svizzera italiana in Lugano, geboren 1988. Kindheit in der
                            Surselva (romanischsprachige Schweiz) und dem Moesano
                            (italienischsprachige Schweiz), in einer schweizerisch-polnischen und
                            mehrsprachigen Familie. Patensohn des Jubilars.
                        </div>
                        <div className="clearfloat"></div>
                    </div>
                </div>
            </div>

            <div id="Main">
                <div id="Articles" className="grid_14">
                    <section id="posts">
                        <article className="post">
                            <p>
                                Je ne compte pas plus que mon frère baser ma vie sur les langues, cela
                                n’a pas non plus été l’objectif de nos parents, mais je suis
                                heureux d’avoir mes langues pour leur utilité et la diversité du
                                bien-être qu’elles m’apportent. En ce sens, même si, comme on le
                                verra, les résultats auraient pu être encore meilleurs, l’idée de
                                nous donner, à mon frère et à moi, une richesse pour la vie, une
                                variété de «chez-nous» linguistiques, «d’airs à respirer»,
                                cette idée a été bonne et elle s’est réalisée sans à-coups et
                                sans pression insupportable.
                            </p>

                            <h1>1 Présentation générale</h1>
                            <p>
                                Aujourd’hui, j’ai 23 ans et j’habite dans la partie italophone des
                                Grisons, le canton trilingue qui forme l’est de la Suisse.
                            </p>
                            <p>
                                Je suis né à Glion, en allemand Ilanz, la seule ville romanche,
                                et la «première ville sur le Rhin», comme elle se présente. J’ai
                                passé mes premières années dans la partie romanchophone des Grisons,
                                dans un petit village du nom de Zignau. J’ai grandi là jusqu’à mes
                                cinq ans.
                            </p>
                            <p>
                                Nous avons ensuite déménagé en Mesolcina, une des quatre vallées
                                italophones des Grisons, d’abord à Roveredo où j’ai fait
                                l’école enfantine, l’école primaire et l’école secondaire, puis
                                à San Vittore. La langue de l’enseignement dans la vallée est
                                l’italien, l’allemand étant enseigné comme première langue
                                étrangère à partir de la quatrième primaire. La seconde langue
                                étrangère était encore à mon époque le français.
                            </p>
                            <p>
                                Grâce à l’accord intercantonal existant entre les Grisons et le
                                Tessin, j’ai fréquenté le Liceo cantonale de Bellinzone, aux portes
                                de la Mesolcina, plutôt que, en internat, l’école cantonale
                                essentiellement germanophone de Coire, de l’autre côté des Alpes. Ma
                                seconde langue étrangère y a été l’anglais. J’ai ensuite
                                commencé des études de droit en français à l’Université de
                                Fribourg avant de changer d’orientation et de m’inscrire en
                                économie à l’Université de la Suisse italienne à Lugano.
                            </p>
                            <p>
                                Mon frère, de deux ans plus jeune, a également fréquenté les écoles
                                de Roveredo – où il a déjà eu l’anglais comme seconde langue
                                étrangère –, puis suivi le gymnase de Bellinzone, avec comme langues
                                étrangères l’allemand, l’anglais et le castillan. Il étudie
                                actuellement la biologie à l’Université de Lausanne.
                            </p>
                            <p>
                                Notre mère est Polonaise. Originaire du sud-est de la Pologne, elle a
                                fait ses études à Gdańsk, où elle a ensuite travaillé comme cadre
                                dans l’administration municipale jusqu’à son mariage et à son
                                émigration en Suisse à l’âge de 34 ans. Elle avait déjà étudié
                                le russe et l’allemand en Pologne et, dès son installation en
                                Surselva romanche, elle a appris les trois autres langues nationales
                                suisses, qu’elle lit et comprend aujourd’hui couramment, sans les
                                parler parfaitement.
                            </p>
                            <p>
                                Depuis son mariage, notre mère est femme au foyer avec un certain
                                engagement social, notamment en faveur de personnes âgées ou
                                handicapées.
                            </p>
                            <p>
                                Notre père est Suisse, du nord de la Romandie, c’est-à-dire de
                                langue maternelle française. D’éducation monolingue, il a appris
                                l’allemand, l’italien et l’anglais à l’école, et a très tôt
                                étudié de lui-même d’autres langues avant de s’engager dans la
                                défense des langues discriminées. Comme la Suisse viole ses propres
                                principes de base en discriminant et en étouffant lentement sa
                                quatrième langue nationale, il a également appris cette langue, le
                                romanche, et s’est concentré à partir d’un certain moment sur sa
                                défense. Auparavant cependant, il avait entre autres étudié le
                                polonais et fait un stage d’un an à l’Université Jagellone de
                                Cracovie. Mes parents parlent donc essentiellement polonais entre eux,
                                mais communiquent également en cas de besoin dans l’une ou l’autre
                                des langues nationales suisses.
                            </p>
                            <p>
                                Notre père a diverses activités professionnelles et culturelles,
                                toutes liées aux langues (traduction, dictionnaires romanche/français,
                                un peu d’enseignement) ou à leur situation (notamment analyses
                                statistiques et sociolinguistiques concernant le romanche pour
                                l’Office fédéral de statistique ou d’autres institutions).
                            </p>
                            <p>
                                Il est important de souligner que, sauf pour les cours et des
                                conférences ou séminaires occasionnels, notre père a toujours
                                travaillé à la maison. Casanier, il y est même certainement encore
                                plus présent que notre mère. Depuis tout petits, mon frère et moi
                                avons toujours pu entrer librement dans son bureau-bibliothèque, et
                                nous y installer à demeure à condition d’être tranquilles quand il
                                travaille – ce que nous avons paraît-il appris étonnamment tôt et
                                facilement.
                            </p>

                            <h1>2 Projet linguistique</h1>
                            <p>
                                Lorsque nos parents se sont mariés et ont décidé d’avoir des
                                enfants, ils avaient (et ils n’ont pas changé d’avis depuis) une
                                attitude fondamentalement semblable quant aux langues à transmettre.
                                Pour notre mère, il n’était absolument pas question de ne pas parler
                                sa propre langue à ses enfants et notre père la soutenait totalement
                                dans cette idée. Notre mère était de son côté d’accord avec ce
                                qui était l’ambition de notre père de nous donner un chez-nous
                                plurilingue aussi vaste et solidement construit que possible. Il était
                                ainsi clair que chacun d’eux non seulement nous parlerait, à mon
                                frère et à moi, sa propre langue, mais encore, le moment venu, nous
                                l’enseignerait de manière à ce que nous sachions la lire et
                                l’écrire correctement quelle que soit la langue de la région où
                                nous fréquenterions l’école.
                            </p>
                            <p>
                                Notre père a dès l’abord pris en considération d’autres langues
                                encore, soit pour que mon frère et moi en ayons une connaissance
                                systématique, soit pour que nous en connaissions l’existence par
                                certaines expressions précises. Alors que notre mère nous a toujours
                                parlé exclusivement en polonais (sauf en présence d’autres personnes
                                ne parlant pas polonais qu’elle ne voulait pas exclure), il a ainsi
                                choisi d’aller sciemment à l’encontre du principe généralement
                                recommandé «une personne, une langue», qui ne le convainc pas du
                                tout, et nous a toujours, à des degrés divers et dans des buts
                                différents, parlé en plusieurs langues, tout en veillant à nous
                                assurer la connaissance primordiale du français.
                            </p>
                            <p>
                                Nos parents n’ont pas planifié dans les détails un programme étalé
                                sur quinze ou vingt ans. Ils avaient un objectif général, nous assurer
                                un multilinguisme, et y ont travaillé de leur mieux, en s’adaptant et
                                réagissant aux circonstances, et en tenant compte des capacités que
                                leurs enfants montraient, ainsi que des désirs qu’ils exprimaient.
                            </p>

                            <h1>3 Réalisation du projet</h1>

                            <h2>3.a Périodes et manières </h2>
                            <p>
                                La mise en œuvre du projet s’est déroulée dans des conditions
                                différentes et selon un mode partiellement différent avant et après
                                notre déménagement à Roveredo.
                            </p>
                            <p>
                                En Surselva, la langue naturelle est le romanche. Mais le romanche est
                                une langue discriminée et soumise à la pression de l’allemand, sous
                                la double forme de la langue standard et du dialecte alémanique. Nos
                                parents ont, précisément pour cette raison, pris un soin particulier
                                pour limiter notre exposition à l’allemand, et surtout à
                                l’alémanique, en même temps qu’ils soutenaient la présence du
                                romanche dans notre entourage. Dès notre naissance, notre père nous a
                                ainsi beaucoup parlé en romanche en plus du français. Pour le
                                principe, par goût, mais sachant aussi que nous déménagerions
                                éventuellement dans une région de langue italienne (entre autres parce
                                que notre mère, comme beaucoup de Polonais, ressent une affinité
                                particulière pour l’«Italie» en tant que région et culture), il
                                nous a également parlé assez régulièrement en italien pendant toute
                                la période de Zignau. Il a par ailleurs utilisé de façon
                                systématique quelques éléments de différentes autres langues pour
                                des sujets ciblés, afin de nous rendre un peu plus tangible la
                                multiplicité des langues.
                            </p>
                            <p>
                                Par contre, à partir de notre installation en Mesolcina italophone,
                                notre père a complètement cessé de nous parler italien et a laissé
                                au nouveau milieu le soin de poursuivre notre développement dans cette
                                langue. Chacun à sa manière, nos parents ont également fait très
                                attention à ce que l’italien, soutenu par l’école et une bonne
                                part de notre environnement, n’écrase pas nos autres langues. À
                                l’inverse, l’allemand n’étant plus une menace, ils ont favorisé
                                sa présence (mais jamais celle de l’alémanique) de diverses façons,
                                sans toutefois en arriver à s’en servir activement avec nous – cela
                                n’aurait plus été naturel. Quant au romanche, malgré les efforts de
                                notre père, sa présence s’est peu à peu réduite, mais sans jamais
                                disparaître.
                            </p>
                            <p>
                                D’une manière générale, le déménagement à Roveredo a coïncidé
                                pour moi avec le début de l’école, plus exactement de l’école
                                enfantine («asilo»). Voyant sa réaction triste et envieuse, mes
                                parents ont fréquemment amené mon frère à un «asilo nido» de
                                Bellinzone pendant la première année, puis l’ont inscrit au
                                «preasilo» qui s’était constitué à Roveredo. Pour tous les deux,
                                la césure du déménagement a donc été en même temps le moment où
                                le monde extérieur à la famille est devenu plus présent.
                            </p>
                            <p>
                                Nos parents n’ont pas cherché à nous apprendre à lire et écrire
                                avant que l’école ne le fasse, du moins en ce qui me concerne. Mais
                                à partir du moment où j’ai commencé l’école primaire, ils ont
                                entrepris de nous donner un enseignement complémentaire aussi
                                structuré que possible, qu’ils ont poursuivi de manière régulière
                                jusque vers mes quinze ans pour notre mère, jusque vers mes dix-huit
                                ans pour notre père. En principe chaque jour, nous avions ainsi des
                                leçons de langue de l’ordre d’un quart d’heure à une demi-heure
                                par langue. Notre père a en outre essayé à quelques reprises de nous
                                assurer un enseignement complémentaire en culture générale et en
                                histoire, mais cela n’est jamais allé très loin.
                            </p>
                            <p>
                                Malgré la différence d’âge entre nous, mon frère et moi avons
                                assez rapidement fini par suivre partout le même enseignement de la
                                part de nos parents. Pour mon frère, il s’est agi au début d’une
                                sorte de nouveau jeu, mais il s’est ensuite inséré dans le cours
                                normal des leçons, si bien que, en fait, il a commencé à étudier
                                avec nos parents plus précocement que moi. Pendant plusieurs années,
                                on a pu sentir que, en général, j’avais, en raison de mon âge, plus
                                de facilité que lui, mais lorsque, ponctuellement, il me dépassait,
                                c’était un choc pour moi – et une fierté pour lui. Cette
                                émulation un peu particulière nous a sans doute servi.
                            </p>
                            <p>
                                Nos parents ont rencontré plusieurs difficultés dans la tâche
                                qu’ils s’étaient assignée. La plus importante était, ils le
                                savaient, leur impréparation. Notre mère avait certes occupé un poste
                                qui requérait une bonne qualité et une maîtrise pratique approfondie
                                de la langue, mais elle n’était pas en mesure d’exprimer et
                                expliquer des règles d’orthographe et de grammaire. Notre père, par
                                contre, avait déjà eu des occasions d’enseigner des langues, mais
                                soit à des adultes, soit à des adolescents; du fait de ses études de
                                langues et de son travail avec les langues, il avait par ailleurs plus
                                de facilité pour nous expliquer les règles de grammaire et
                                d’orthographe. Mais ni l’un ni l’autre n’avait de formation
                                pédagogique ou autre qui les aurait aidés, notamment à mieux
                                maîtriser la situation particulière où la relation maître-élève va
                                de pair avec le lien parent-enfant. Une autre difficulté a été, dans
                                une partie des cas, l’absence de matériel adapté à notre situation
                                et à nos besoins spécifiques.
                            </p>
                            <p>
                                L’enseignement que nous ont donné nos parents a représenté pour eux
                                comme pour nous un effort quotidien. Mais justement parce qu’il était
                                une habitude quotidienne, il n’a pas été perçu comme une charge
                                insupportable. Nos parents nous ont également toujours expliqué, de
                                manière adaptée à notre âge, les motifs et les buts de ces efforts,
                                en faisant appel à notre raison, qu’ils ont ainsi également
                                développée. Périodiquement, ils rediscutaient avec nous les
                                modalités et les objets des cours. L’enseignement du polonais et du
                                français était un désir très fortement exprimé de leur part et
                                représentait un minimum que nous étions amenés à accepter pour les
                                raisons qu’ils nous expliquaient. Pour les autres objets que notre
                                père nous a enseignés, il s’agissait explicitement d’un
                                supplément. Ce supplément était soit quelque chose qu’il souhaitait
                                nous apporter (romanche, culture générale) – mais seulement dans la
                                mesure où cela ne représentait pas une trop grande charge –, soit
                                quelque chose que nous voulions nous-mêmes (portugais, et dans une
                                large mesure allemand). Sur la base des expériences faites, de la
                                situation à l’école, des nécessités et des envies, nous discutions
                                donc ensemble de l’éventail des cours et du temps à leur consacrer,
                                le point cardinal étant de déterminer la base journalière possible et
                                raisonnable pour un effort constant.
                            </p>
                            <p>
                                Jusque dans notre adolescence, notre père nous a également
                                régulièrement accompagnés au moment du coucher. Il nous chantait des
                                chansons traditionnelles, surtout romanches, romandes ou françaises que
                                nous choisissions, et surtout nous lisait des histoires ou, plus
                                souvent, nous en inventait, généralement en français, mais en faisant
                                aussi une place, décroissante, au romanche. Ce (long) moment régulier
                                de plaisir et délassement purs a clairement contribué a affermir le
                                français en nous.
                            </p>
                            <p>
                                Au total, mon frère et moi avons bénéficié pendant plus de dix ans
                                de différents enseignements qui ont exigé une discipline souvent
                                difficile dans le détail, au jour le jour, mais qui nous ont toujours
                                laissé le temps de jouer comme les enfants et adolescents de notre
                                âge, à la maison ou à l’extérieur. Peu à peu cependant, les
                                exigences de l’école ont augmenté, nous laissant moins de temps. Le
                                changement décisif est survenu avec l’entrée au gymnase: le trajet
                                pour aller en ville et en revenir prend beaucoup de temps, et nous
                                étions donc toute la journée hors de la maison. Notre motivation et
                                notre disposition à maintenir une constance dans l’effort ont
                                baissé. Après différentes crises et tenant compte du fait que, en
                                français, nous avions de toute façon terminé le programme d’étude
                                de la langue elle-même, notre père a coupé court et renoncé aux
                                enseignements réguliers, restant à disposition pour des interventions
                                ponctuelles quand nous en avions besoin, en langues, en culture
                                générale, en rédaction ou en recherche.
                            </p>
                            <p>
                                Depuis longtemps, il s’est instauré une sorte de collaboration
                                linguistique entre les membres de la famille. Chacun, parent ou enfant,
                                aide les autres dans la mesure des besoins, de ses possibilités et du
                                raisonnable, par exemple en soufflant des mots lorsqu’ils manquent, en
                                corrigeant des fautes ou des influences d’une langue sur l’autre, ou
                                en apportant sa compétence pour la compréhension ou la rédaction de
                                textes. Nous fonctionnons ainsi un peu comme une équipe, en même temps
                                qu’une banque de données à l’usage de chacun. (Il y a d’ailleurs
                                collaboration et coopération dans d’autres domaines que les langues,
                                toujours selon des capacités et prédispositions individuelles.)
                            </p>

                            <h2>3.b Langues</h2>

                            <h3>Polonais</h3>
                            <p>
                                Le développement du polonais était essentiellement la tâche de notre
                                mère. En dehors de situations très particulières et très
                                ponctuelles, elle ne nous a jamais parlé qu’en polonais, et s’est
                                toujours battue pour que mon frère et moi lui parlions en polonais. À
                                certaines périodes, mon frère surtout a eu tendance à vouloir lui
                                parler en français (dans sa petite enfance), puis en italien, mais elle
                                est parvenue à maintenir le polonais comme langue de communication
                                entre nous et elle, à ceci près qu’elle a accepté que nous
                                insérions des termes italiens ou français qui ne nous venaient pas
                                immédiatement à l’esprit en polonais, ou dont elle s’est
                                elle-même habituée à les entendre, voire à les utiliser.
                            </p>
                            <p>
                                Nous savions que notre père parlait polonais puisque c’est en
                                polonais que nos parents communiquent. Mais, sauf rares exceptions
                                motivées, il ne nous parlait pas polonais, même en présence de notre
                                mère – qui n’était cependant pas exclue puisqu’elle comprenait
                                chacune des langues courantes que notre père parlait avec nous. Lui et
                                nous nous servions en cas de besoin du polonais pour éviter d’être
                                compris par des personnes présentes, mais cela nous faisait un effet
                                bizarre. Notre père n’a commencé à nous parler (aussi) polonais de
                                façon plus régulière qu’à partir du moment où il n’y a plus eu
                                aucun risque que le polonais ne devienne la seule langue pratique de la
                                famille, et où nous avons été suffisamment grands pour comprendre la
                                situation – et pour pouvoir corriger les fautes de notre père.
                                Depuis, lui et nous nous servons du polonais en particulier en présence
                                de notre mère, et parfois par inadvertance lorsque la situation est
                                liée à elle. Le polonais est également la principale langue
                                d’exclusion des présents – sauf bien sûr si ceux-ci parlent
                                polonais –: mon frère n’a ainsi jamais parlé autant polonais avec
                                moi ou notre père que depuis qu’il nous téléphone de Lausanne.
                            </p>
                            <p>
                                Dans notre petite enfance, nos deux grands-parents polonais
                                aujourd’hui décédés ont fait des séjours assez longs en Suisse et,
                                comme ils ne comprenaient que le polonais, cela renforçait la langue en
                                nous. Notre mère, mon frère et moi avons également passé ensemble,
                                presque chaque année, de longues périodes de vacances en Pologne, chez
                                nos grands-parents non loin de la frontière ukrainienne ou chez des
                                amis de Varsovie ou de Gdańsk, ce qui a élargi notre éventail
                                d’emploi de la langue. Nous avons par exemple eu l’occasion de jouer
                                avec des enfants de notre âge, et découvrir leur polonais de jeunes
                                nous a causé au début un certain choc.
                            </p>
                            <p>
                                Surtout dans notre petite enfance, notre mère a également cherché à
                                soutenir le polonais en nous procurant des cassettes de films, et
                                notamment de dessins animés, en polonais, et des livres pour enfants en
                                polonais, qu’elle nous lisait et expliquait. À partir du moment où
                                nous avons appris à lire et écrire à l’école, elle nous a appris
                                à lire et à écrire en polonais, puis s’est efforcée de nous
                                apprendre la grammaire et la rédaction en polonais. La grande
                                difficulté qu’elle a rencontrée, en plus du fait qu’elle n’avait
                                pas de formation spécifique pour l’enseignement de la langue, a été
                                qu’elle n’est jamais parvenue à trouver du matériel qui convienne
                                réellement à notre situation particulière. Pendant des années, nous
                                avons étudié quotidiennement un moment avec elle, mais, quand j’ai
                                eu environ 15 ans, plus ou moins d’un commun accord, nous avons peu à
                                peu cessé les leçons de polonais. Vers cette époque, lors de nos
                                vacances d’été en Pologne, notre mère a trouvé une enseignante
                                professionnelle pour nous donner des leçons particulières, mais cela a
                                été trop peu pour être vraiment utile.
                            </p>
                            <p>
                                Un manque important par rapport à d’autres langues a été
                                l’impossibilité de capter les chaînes de télévision polonaise à
                                une période où cela aurait pu nous intéresser et donc nous être
                                utiles. Ces dernières années, mon frère et moi nous sommes cependant
                                habitués à regarder sur Youtube une série comique des années 90
                                très connue en Pologne.
                            </p>
                            <p>
                                Nous continuons à avoir des contacts fréquents, téléphoniques ou en
                                personne, avec des parents, amis ou connaissances polonais habitant en
                                Pologne ou en Suisse, et, sous certains aspects, on peut même dire que
                                le cercle de gens avec lequel mon frère et moi communiquons en polonais
                                s’est élargi par rapport aux années 1990, quand, pendant des mois,
                                notre mère était la seule référence pour le polonais. En jouant en
                                ligne, mon frère a même développé des contacts avec des Polonais de
                                Pologne.
                            </p>

                            <h3>Français</h3>
                            <p>
                                Pendant des années, notre père a été la source essentielle pour le
                                développement du français chez mon frère et moi. Les Romanches
                                formaient la plus grande partie de son cercle habituel de relations et
                                il n’avait conservé que peu de contacts avec la Romandie, où nous
                                n’allions que rarement. À part lui, nous n’avions guère que nos
                                grands-parents suisses pour parler français. Mais ceux-ci venaient
                                assez souvent en visite, et mon frère et moi passions également chaque
                                année au moins une ou deux semaines de vacances avec eux, en Suisse ou
                                dans différents pays.
                            </p>
                            <p>
                                Notre père parle un français qui à la fois est très châtié et
                                revendique l’utilisation de tournures populaires condamnées par les
                                grammairiens, ainsi que de mots et expressions spécifiques de la Suisse
                                romande. Cela a été pour lui une raison supplémentaire pour
                                s’efforcer d’élargir l’éventail des sources qui nous apportaient
                                le français, en favorisant des rencontres occasionnelles avec
                                d’autres francophones que lui et notre famille proche. Nous avions par
                                ailleurs la possibilité de regarder la télévision romande. Pendant
                                notre petite enfance, nous avons également reçu beaucoup de cassettes
                                de films pour enfants en français, par exemple d’une émission de la
                                TSR qui s’appelait les Babibouchettes. Nous regardions ces cassettes
                                presque jusqu’à les savoir par cœur et, d’après les notes que
                                notre père a prises pendant des années, chaque nouvelle cassette nous
                                apportait un enrichissement clairement mesurable.
                            </p>
                            <p>
                                Notre père a commencé à nous apprendre à lire et écrire le
                                français en même temps que notre mère a entrepris de le faire pour le
                                polonais. Il nous a ainsi enseigné de manière systématique
                                l’orthographe et la grammaire françaises. Contrairement à notre
                                mère, il a pu se servir de matériel didactique valable, le même que
                                celui utilisé dans les écoles de Romandie ou de France. Son
                                expérience du travail linguistique l’a aussi aidé dans cet
                                enseignement. Par contre, comme il n’a pas de formation pédagogique,
                                il ne savait pas plus que notre mère comment nous faire exercer
                                efficacement la rédaction; sa stratégie a été de reconnaître
                                ouvertement ses difficultés, et d’essayer de nous motiver pour
                                collaborer au développement d’un système qui nous convienne. Il
                                n’était d’ailleurs absolument pas satisfait du niveau
                                d’enseignement de la rédaction (italienne) à l’école, mais il
                                n’a pas réussi, à ses propres yeux, à pallier cette lacune.
                            </p>
                            <p>
                                Ma volée a été la dernière des Grisons à avoir le français comme
                                deuxième langue étrangère, et j’ai eu trois ans de français à
                                l’école secondaire. Très raisonnablement, l’enseignante, sachant
                                que j’étais de langue «paternelle» française et surtout que
                                j’étudiais le français depuis des années à la maison, a convenu
                                avec moi d’un statut particulier. J’ai ainsi été régulièrement
                                présent en classe, j’ai également fait normalement les exercices et
                                les épreuves, mais pour le reste je lisais ou travaillais pour
                                moi-même, en étant par ailleurs à disposition de l’enseignante
                                quand celle-ci souhaitait que j’intervienne, soit pour aider mes
                                camarades, soit pour, en quelque sorte, porter témoignage du français,
                                du français romand, et apporter ainsi une touche supplémentaire à
                                l’enseignement. Cette situation et ce rôle ont contribué à ma
                                conscience de moi-même.
                            </p>
                            <p>
                                Mon frère aurait pu suivre l’enseignement devenu facultatif du
                                français, mais cela n’aurait évidemment eu aucun sens et il a choisi
                                une autre branche complémentaire. De même, au gymnase, nous avons tous
                                deux évité la solution de facilité qui aurait consisté à prendre le
                                français comme première langue étrangère, et avons choisi
                                l’allemand.
                            </p>
                            <p>
                                Notre père a également essayé de nous donner des notions de la
                                littérature française mais, ici aussi, il lui manquait l’expérience
                                pédagogique. En plus, il se souvenait de son ennui lors des leçons de
                                littérature, française ou autre, et comprenait le nôtre devant
                                l’obligation qui nous était faite d’analyser à l’école
                                Leopardi, Boccace ou Dante. S’il était tenace pour nous enseigner au
                                mieux le programme sur lequel nous nous étions mis d’accord, il
                                répugnait à nous imposer l’étude d’auteurs précis, soit parce
                                qu’il s’agissait d’écrivains qu’il n’aime pas et qu’il ne
                                pouvait pas se décider à les faire subir à d’autres, soit au
                                contraire parce qu’il les aime, mais ne voulait pas imposer ses
                                propres goûts.
                            </p>
                            <p>
                                Un point important est que notre père a toujours été très vigilant
                                quant à la qualité de notre français et a suivi avec attention le
                                vocabulaire que nous acquérions à l’école. Dès qu’il nous
                                entendait employer un mot nouveau appris en italien, il nous en donnait
                                l’équivalent français, ouvertement, ou en le glissant dans la
                                conversation. S’il s’agissait d’un terme spécifique qu’il ne
                                connaissait pas et dont il pouvait prévoir que nous l’utiliserions
                                d’autres fois, il le cherchait dans le dictionnaire. De même, il nous
                                a signalé nos calques de l’italien, en nous expliquant par exemple
                                que lui nous avait compris, mais que d’autres francophones ne nous
                                comprendraient pas forcément, ou donneraient un autre sens à ce que
                                nous leur disions. Il nous a en outre, de façon répétée, expliqué
                                que le français, comme chaque langue du reste, diffère un peu selon
                                les régions, les personnes, les situations et les générations, en
                                nous donnant des exemples, afin de compenser le faible éventail des
                                sources dont nous disposions en l’occurrence.
                            </p>
                            <p>
                                À partir du moment où nous avons su lire couramment, et pendant une
                                dizaine d’années, nous avons eu un abonnement à une bibliothèque de
                                Suisse romande qui nous envoyait de gros paquets de livres adaptés à
                                notre âge et répondant aux spécifications que nous donnions
                                nous-mêmes. Nous avons ainsi fait venir des ouvrages pratiques sur des
                                sujets qui nous intéressaient, des romans d’aventure, et des BD.
                            </p>
                            <p>
                                Notre père a lui-même une très bonne collection de bandes dessinées
                                (que nous avons encore développée à trois), dont nous avons lu et
                                relu nombre de séries. Contrairement à l’image négative que
                                beaucoup en ont, la BD, en tout cas celle de langue française dans des
                                séries comme Achille Talon, développe réellement la connaissance de
                                la langue – surtout quand, comme moi et encore plus mon frère, on lit
                                attentivement les textes, encore et encore, et en arrive à connaître
                                presque par cœur une multitude de dialogues, de gags et de jeux de mots
                                que l’on s’est fait expliquer au besoin.
                            </p>
                            <p>
                                Le français a ainsi été la langue dans laquelle nous avons le plus
                                lu, même en tenant compte de ce que nous avons lu pour l’école en
                                italien ou en d’autres langues. D’après ce qu’en disent nos
                                parents, nous avons cependant beaucoup moins lu qu’eux ne l’ont fait
                                à notre âge et, en fait, nous les croyons. Tout simplement, nous avons
                                des possibilités de distraction plus nombreuses et plus variées
                                qu’eux n’en ont eues, et nous nous en servons.
                            </p>
                            <p>
                                Le français est une langue dans laquelle on trouve tous les genres de
                                films que l’on veut, et mon frère et moi avons regardé un nombre
                                incalculable de films en français, ce qui nous a permis de diversifier
                                notre langue et d’adopter des tournures nouvelles. Le temps que j’ai
                                passé à Fribourg a fait le reste, et mon frère trouve aujourd’hui
                                les mêmes possibilités à Lausanne.
                            </p>

                            <h3>Italien</h3>
                            <p>
                                Comme je l’ai déjà mentionné, notre père nous a parlé entre
                                autres en italien tant que nous avons habité en Surselva. Certes, ce
                                n’est pas sa langue maternelle, mais c’est une langue qu’il aime
                                et une langue quotidienne de travail. Il y avait donc pour lui un
                                certain naturel à l’utiliser. Selon son évaluation, un cinquième,
                                voire un quart de ce qu’il nous disait pouvait être en italien. Il
                                était cependant presque l’unique personne à nous parler dans cette
                                langue, même si, vers la fin de la période, il encourageait un voisin
                                italien à nous parler lui aussi en italien plutôt qu’en romanche.
                            </p>
                            <p>
                                Nous avions également la possibilité de regarder la TSI, la
                                Télévision suisse de langue italienne, et nous avons eu une ou deux
                                cassettes en italien.
                            </p>
                            <p>
                                Comme on le verra au chapitre suivant, je ne parlais pas vraiment
                                italien en quittant la Surselva, mais je le comprenais autant qu’un
                                enfant de cinq ans peut comprendre une langue, et je me suis mis à le
                                parler dès notre arrivée à Roveredo, en l’améliorant rapidement.
                            </p>
                            <p>
                                Mon frère par contre est resté un an presque sans parler italien. Il
                                comprenait tout, mais ne répondait pas, ou que très peu – et alors
                                en français. Lorsque nous jouions avec d’autres enfants, il me
                                demandait en français de traduire en italien ce qu’il voulait dire,
                                selon un système qu’il avait déjà utilisé s’agissant du
                                romanche. Puis, lorsqu’il s’est vraiment lancé, il a impressionné
                                par sa facilité dans sa nouvelle langue.
                            </p>
                            <p>
                                Après notre installation en Mesolcina, nos parents ont fait très
                                attention à ce que l’italien devienne certes pour mon frère et moi
                                une langue pleinement naturelle, et reste pour toute la famille une
                                langue utilisée normalement et volontiers par tous avec le monde
                                extérieur, mais qui n’en devait pas moins rester à la porte de la
                                maison. Tant que cela a été utile ou nécessaire, notre père nous a
                                ainsi encore aidés à développer notre italien en corrigeant des
                                fautes ou des emprunts à d’autres langues, mais il ne nous a plus
                                parlé dans cette langue que lorsqu’il s’agissait de ne pas exclure
                                des personnes de langue italienne avec lesquelles nous nous trouvions.
                            </p>
                            <p>
                                Au tout début, je lui ai eu demandé la permission de lui raconter en
                                italien des choses par exemple de l’école enfantine, pour lui montrer
                                mes progrès, et il m’a écouté avec attention. Mais plus tard, une
                                fois où je lui disais que l’italien était désormais pour moi une
                                langue naturelle et que j’avais envie de m’en servir avec lui, il
                                m’a expliqué qu’il était content de cette réussite, que cela
                                avait été son but, mais qu’il fallait faire un choix et qu’il
                                s’agissait désormais de saisir toutes les occasions de pratiquer nos
                                autres langues, de crainte que nous ne les développions pas
                                suffisamment. Sauf circonstances exceptionnelles, je ne me suis ainsi
                                finalement jamais «vraiment» servi de l’italien ni avec lui, ni avec
                                notre mère.
                            </p>
                            <p>
                                Mon frère, par contre, a à plusieurs reprises développé une tendance
                                à parler italien en famille, et notre père m’a à chaque fois
                                demandé mon aide de frère aîné, comprenant mieux la situation et les
                                besoins, pour arrêter cette tendance.
                            </p>
                            <p>
                                Aujourd’hui encore, notre père nous aide de façon très naturelle en
                                italien lorsque nous lui demandons un soutien qu’il est en mesure de
                                nous fournir. De son côté, il a très tôt valorisé notre compétence
                                en italien en nous consultant lorsqu’il avait un doute ou cherchait
                                une formulation pour son travail, et pensait que nous étions
                                susceptibles de pouvoir lui répondre. Nous lui signalons également des
                                fautes qu’il fait, et essayons aussi d’aider notre mère à
                                améliorer son italien.
                            </p>
                            <p>
                                L’italien que je parle est évidemment, comme pour mon frère,
                                l’italien de Suisse, alors que celui de notre père, provenant de
                                sources diverses, est plus composite. En Mesolcina, le dialecte, très
                                différent du standard, est encore très répandu. Contrairement à nos
                                deux parents, mon frère et moi avons ainsi appris à le comprendre sans
                                autre, et nous en avons pratiqué une forme un peu mixte entre nous,
                                mais nous ne nous en sommes jamais servis avec des dialectophones.
                            </p>

                            <h3>Romanche</h3>
                            <p>
                                La connaissance que mon frère et moi avons du romanche s’est
                                développée de manière très erratique et notre lien avec cette langue
                                s’en ressent.
                            </p>
                            <p>
                                En Surselva, le romanche était la langue des voisins, des amis, des
                                gens avec lesquels notre père travaillait. À terme, il serait de toute
                                façon devenu pour nous une langue d’usage courant comme l’italien
                                l’est devenu plus tard. Nos parents ont cependant fait leur possible
                                pour hâter ce processus. Pour notre père, qui habitait déjà depuis
                                des années dans la région de langue sursilvane et qui était très
                                fortement impliqué dans le mouvement romanche, le romanche était
                                devenu une langue quotidienne parfaitement familière, dans laquelle il
                                lui était – et reste – naturel de nous parler. Notre mère a elle
                                aussi très rapidement appris la langue, et la parlait avec notre
                                entourage.
                            </p>
                            <p>
                                En cinq ans, le romanche est effectivement devenu une langue d’usage
                                normal pour moi. Mon frère, lui, n’a eu que trois ans en Surselva, et
                                il n’a pas eu le temps de développer une pratique aussi active de la
                                langue, mais le romanche était pour lui aussi une des trois langues
                                habituelles.
                            </p>
                            <p>
                                Après notre déménagement, notre père a donc cherché à maintenir et
                                développer cette base déjà acquise. Pendant environ cinq ans, notre
                                famille a par ailleurs fréquemment passé des fins de semaines à
                                Rumein, un hameau d’une vallée latérale de Surselva, dans une maison
                                gérée par le P. Flurin Maissen, avec lequel notre père travaillait.
                                Nous nous sentions à peu près chez nous dans cette grande maison où
                                notre père avait habité pendant des années avant son mariage. «Il
                                Pader» était une sorte de grand-père, ou d’arrière-grand-père,
                                avec lequel nous étions très familiers. Par exemple, à l’âge de
                                neuf ans, je lui ai expliqué pendant une heure, en romanche bien sûr,
                                que l’on ne répète jamais la même chose de la même façon, et il
                                m’a écouté avec attention et m’a soutenu dans mon discours. Nous
                                rencontrions également nos deux marraines, toutes deux romanches. Nous
                                avions donc souvent l’occasion de parler romanche avec d’autres
                                personnes que notre père et, dans une certaine mesure, d’élargir
                                notre vocabulaire. Cependant, déjà à cette époque, le romanche
                                prenait du retard par rapport à nos autres langues.
                            </p>
                            <p>
                                Après la mort du P. Flurin, nos visites en Surselva se sont faites
                                rares, la région elle-même est devenue abstraite pour nous. Le retard
                                du romanche s’est accentué, nous ne nous en servions plus
                                qu’exceptionnellement avec notre père – pour lui faire un plaisir
                                particulier ou comme plaisanterie –, et avons également eu de plus en
                                plus de réticences à ce que lui nous parle en romanche.
                            </p>
                            <p>
                                Pour cela, ou malgré cela, nous avons accepté la proposition de notre
                                père de ne pas nous contenter des contacts épisodiques avec le
                                romanche, et d’affermir la langue en l’étudiant activement,
                                quoiqu’avec beaucoup moins d’intensité que le français,
                                l’objectif principal. Pendant plusieurs années, nous nous sommes
                                ainsi servis avec lui de manuels scolaires que les enfants de notre âge
                                utilisaient en Surselva. Mais nous n’avions pas la même base
                                qu’eux, et les manuels étaient d’une qualité et d’un intérêt
                                très inférieurs au matériel dont nous nous servions en français, ce
                                qui était peu motivant. Même si nous avons pu ainsi entretenir et
                                développer encore un peu notre romanche, la différence de niveau avec
                                nos autres langues principales n’a pas cessé de grandir.
                            </p>

                            <p>
                                Plusieurs autres éléments ont également joué contre le romanche.
                                Notre père lui-même a commis l’erreur de trop souligner, dans
                                l’idée de nous inciter à le combler, le retard pris dans une langue
                                qui nous était autrefois parfaitement familière. Il aurait dû au
                                contraire mettre en relief (il l’a fait plus tard) ce que nous avions
                                en romanche malgré que ce ne soit pas notre langue tout à fait au
                                même titre que le polonais ou le français. Par ailleurs, tout en
                                s’exprimant très naturellement en romanche, il parle la langue de
                                façon beaucoup plus latine que le commun des Romanches qui, eux,
                                utilisent de nombreux mots alémaniques, et encore plus de calques
                                directs de l’allemand.
                            </p>
                            <p>
                                La différence est également sensible entre ce que les manuels dont
                                nous nous sommes servis enseignent, et la langue abâtardie que les
                                jeunes parlent. À la fin de l’école secondaire, un enseignant de
                                Surselva nous a demandé, à mon frère et à moi, de nous mettre à
                                disposition pour correspondre par courriel avec certains de ses élèves
                                de notre âge afin de leur permettre de découvrir en romanche une autre
                                région linguistique que la leur. L’expérience a été désastreuse
                                pour mon lien avec le romanche, car j’ai découvert que, sans école
                                romanche, avec seulement la base réduite que les leçons faites avec
                                notre père m’avaient apportée, j’écrivais un romanche souvent
                                plus correct et en tout cas plus soigné que mes correspondants.
                            </p>
                            <p>
                                Mon frère et moi avons aussi pris conscience du rapport un peu ambigu
                                de notre père avec le monde romanche: il a un amour pour la langue, et
                                surtout une indignation profonde pour la discrimination qu’elle subit,
                                mais, vis-à-vis des Romanches, il balance entre compréhension pour
                                leur situation et exaspération devant leur résignation. Tout en
                                continuant à avoir des contacts avec des Romanches, et notamment avec
                                nos marraines, mon frère et moi avons commencé à ressentir une
                                exaspération semblable, mais qui, chez nous, est beaucoup moins
                                compensée par la tolérance et la compréhension, et qui nuit elle
                                aussi à notre lien avec la langue romanche.
                            </p>
                            <p>
                                Notre romanche ne s’en est pas moins encore développé sous un angle
                                particulier. Pendant longtemps, il avait été essentiellement le
                                sursilvan, même si ma marraine est surmirane, et même si notre père
                                nous avait souvent incité à regarder avec lui les quelques minutes de
                                nouvelles quotidiennes ou la demi-heure dominicale que la SSR, la
                                télévision suisse, concède au romanche à la place d’une chaîne
                                propre comme pour les autres langues nationales. Étant donné qu’il
                                s’agit de nouvelles locales et régionales, on y entend tous les
                                idiomes. Au début, ce n’était pas facile, et notre père nous aidait
                                en nous rendant attentifs à quelques différences caractéristiques.
                            </p>
                            <p>
                                Deux éléments spécifiques nous ont permis de nous familiariser
                                beaucoup plus avec les autres idiomes romanches. Notre père recevait
                                des reportages télévisés romanches à traduire pour la TSI, qui les
                                reprenait avec des sous-titres en italien. Lorsqu’il a commencé à
                                perdre l’ouïe, il s’est avéré que nous pouvions l’aider à
                                identifier ce qui se disait dans les vidéos, en écoutant encore et
                                encore les passages où le bruit de fond ou le brouhaha du public
                                gênaient la compréhension. Nous nous sommes ainsi formé l’oreille
                                aux autres idiomes, et j’ai pu constater plus tard avec beaucoup de
                                satisfaction que, contrairement à bien des Romanches, je n’ai pas de
                                difficulté à discuter avec des Romanches d’autres idiomes et
                                habitués eux-mêmes à des interlocuteurs sursilvans. Mon frère et moi
                                nous sommes par ailleurs familiarisés avec l’écriture des autres
                                idiomes grâce à une collaboration spécifique qui s’est développée
                                avec notre père: d’une manière générale, nous lui lisons à haute
                                voix les textes qu’il vient de traduire pour lui en permettre un
                                dernier contrôle – et le romanche, sous toutes ses formes, est
                                l’une des langues qu’il traduit.
                            </p>

                            <h3>Allemand</h3>
                            <p>
                                Notre famille a toujours beaucoup regardé la télévision en allemand
                                (jamais en alémanique). De ce point de vue limité, l’allemand a donc
                                toujours été une langue familière pour mon frère et moi. À partir
                                de notre installation en Mesolcina, notre père nous a parfois, de son
                                propre chef ou à notre demande, expliqué comment on dit ceci ou cela
                                en allemand. Pendant longtemps, il nous a assuré une traduction
                                simultanée des films allemands que nous regardions en famille, ce qui
                                nous a permis de développer une compréhension passive de la langue.
                                Par contre, même si nous le pourrions, ni nos parents ni nous n’avons
                                jamais essayé de parler réellement allemand en famille. Nous nous en
                                servons exclusivement comme objet d’étude, instrument de travail, et
                                dans nos relations avec des personnes de langue allemande.
                            </p>
                            <p>
                                Le tournant décisif s’est produit au moment où j’ai commencé
                                l’étude active de l’allemand comme première langue étrangère, en
                                4e primaire. Pour autant que je m’en souvienne, j’avais envie
                                d’aller plus vite et plus loin que ce que l’école me donnait. Notre
                                père a donc commencé à m’assurer un enseignement de l’allemand,
                                non pas complémentaire à celui que je recevais à l’école, mais
                                totalement indépendant de lui, en se servant du matériel scolaire en
                                usage dans les classes de mon âge des écoles romandes.
                            </p>
                            <p>
                                Je pense que c’est plutôt à l’initiative de mon frère que notre
                                père a commencé à lui apprendre l’allemand à lui aussi. Comme
                                j’avais quant à moi pris une certaine avance, il a commencé par se
                                servir avec lui d’un cahier polonais de type ludique destiné aux
                                jeunes débutants de Pologne. Quand, le cahier terminé, notre père a
                                vu que mon frère tenait bon, il s’est servi du même matériel
                                qu’il avait déjà utilisé avec moi. Au bout d’un certain temps,
                                nous avons ensuite pu étudier ensemble, même si, bénéficiant par
                                ailleurs de l’enseignement scolaire, j’avais plus de facilité et
                                notre père devait accorder plus d’attention et de soutien à mon
                                frère qu’à moi. Par contre, à partir du moment où mon frère a
                                commencé l’étude de l’allemand à l’école, il s’est trouvé
                                dans une situation un peu comparable à celle que j’ai connue ensuite
                                avec le français: les cours scolaires n’étaient qu’une faible
                                répétition de ce qu’il savait déjà.
                            </p>
                            <p>
                                Grâce au double enseignement dont nous bénéficiions, nous avions
                                d’ailleurs à l’école secondaire, puis au gymnase, un niveau
                                tellement supérieur à la moyenne de la classe que nous nous sommes
                                trouvés, chacun de son côté, classés par nos enseignants
                                d’allemand dans la catégorie «Muttersprachler», avec des camarades
                                dont au moins un des parents était alémanique. La caractéristique de
                                cette catégorie était qu’on lui demandait plutôt davantage, et
                                qu’on était tendanciellement plus sévère. Malgré cela, nous avons,
                                aussi bien mon frère que moi, obtenu la note maximum pour l’allemand
                                dans notre certificat de maturité.
                            </p>
                            <p>
                                Notre père a cessé son enseignement systématique de l’allemand en
                                même temps que les autres cours qu’il nous donnait, mais a continué
                                à nous soutenir à la demande jusqu’à la maturité. Mon frère
                                surtout a beaucoup fait usage de cette possibilité, pour des révisions
                                de certains thèmes, des exercices, des travaux de rédaction en
                                allemand.
                            </p>
                            <p>
                                Un autre exercice qui a renforcé notre familiarité avec l’allemand a
                                été la collaboration avec notre père telle que je l’ai mentionnée
                                à propos du romanche. Lire régulièrement pendant des années à haute
                                voix des textes allemands de tous genres a certainement contribué de
                                manière non négligeable à améliorer certains aspects de notre
                                maîtrise de la langue. Certes, nous n’avons pas besoin de comprendre
                                absolument tout ce que nous lisons, et nous avons appris à lire des
                                textes ennuyeux sans nous occuper de leur contenu. Mais il n’en
                                demeure pas moins qu’il s’agit d’une lecture sérieuse, précise,
                                où nous devons reconnaître et marquer les périodes, identifier
                                instantanément la structure et la fonction des mots, avoir le réflexe
                                automatique de prévoir la particule séparable, etc. Notre père nous
                                est extrêmement reconnaissant de cette aide, et en apprécie la
                                qualité.
                            </p>
                            <p>
                                Nous regardons également beaucoup de films en allemand, même si mon
                                frère, à l’inverse de moi, a parfois un peu de réticence.
                            </p>
                            <p>
                                Nous n’avons pas énormément d’occasions de parler allemand, et
                                cela a été un handicap, surtout pour mon frère. Pour ma part, en
                                effet, j’ai eu plusieurs fois la chance de faire des séjours d’une
                                certaine durée dans la famille de mon parrain, et cela m’a beaucoup
                                aidé. Cette année, pour la première fois, nous avons fait ensemble un
                                tel séjour, et mon frère a pu vérifier à quel point cela est utile.
                            </p>

                            <h3>Portugais</h3>
                            <p>
                                Vers l’âge de dix ans, influencé par ce que l’on disait à
                                l’époque de l’équipe nationale de football du Portugal, mon frère
                                a demandé à notre père de lui enseigner le portugais. Cela n’a pas
                                été un feu de paille, loin de là. À ses propres yeux, notre père ne
                                pouvait pas refuser, ni discuter le choix de la langue, mais cela l’a
                                placé dans une situation difficile car, même s’il aime le portugais,
                                celui-ci n’est pas parmi ses meilleures langues. Il aurait été
                                beaucoup plus à l’aise en castillan. De plus, il se rendait compte du
                                peu d’occasions qu’il y aurait pour pratiquer les connaissances
                                acquises – lui-même en faisait déjà l’expérience.
                            </p>
                            <p>
                                Une question lancinante a par ailleurs toujours été celle du
                                matériel. Pour la prononciation, on peut se servir de cassettes pour
                                adultes, mais on ne peut pas se servir d’un cours de langue
                                étrangère pour adulte avec un enfant de dix ou onze ans. Notre père
                                n’a jamais trouvé de matériel de portugais langue étrangère pour
                                enfants. Nous nous sommes donc servis de cahiers destinés aux enfants
                                des écoles du Portugal. Je dis à partir d’ici «nous» parce que, au
                                bout de quelques mois, je me suis intégré toujours plus dans les
                                séances d’apprentissage du portugais, et ai fini par étudier au
                                même titre que mon frère.
                            </p>
                            <p>
                                Notre père nous a clairement expliqué la situation, soulignant que,
                                dans le cas du portugais, il apprenait dans une certaine mesure en même
                                temps que nous, surtout s’agissant de textes pour jeunes lusophones.
                                Il y avait un côté intéressant à penser que, pour cette branche,
                                nous étions en partie du même côté de la barrière que notre père.
                                Au total, cependant, cette étude a été ingrate. Nous assimilions
                                certes le contenu des leçons, mais il nous manquait tout le contexte
                                général, et surtout la possibilité de pratiquer la langue de manière
                                naturelle. Un jour, lors d’un long voyage en train, mon père et mon
                                frère ont rencontré des Brésiliennes d’un certain âge. Mon père a
                                engagé la conversation, mais il n’a pas réussi à y entraîner mon
                                frère, méfiant vis-à-vis d’adultes inconnues, et dérouté par
                                l’accent différent.
                            </p>
                            <p>
                                Au bout de quelques années, notre père a eu l’idée de demander à
                                l’ambassade du Portugal s’il existait un enseignement pour les
                                enfants d’immigrés portugais en Suisse italienne, et il a obtenu que
                                nous puissions essayer de participer aux cours organisés à Bellinzone.
                                L’expérience a été négative. Les enfants portugais, s’ils
                                comprenaient l’enseignant mieux que nous – mais nous ne le
                                comprenions pas mal –, parlaient italien entre eux. Et surtout, il
                                s’est avéré que, s’ils avaient malgré tout une pratique du
                                portugais que nous n’avions absolument pas, nous savions plutôt mieux
                                qu’eux l’orthographe et certains points de grammaire, et
                                travaillions aussi mieux. Comme en plus l’horaire et la nécessité
                                d’aller à Bellinzone nous convenaient mal, nous avons abandonné cet
                                essai et continué vaille que vaille avec notre père, jusqu’au moment
                                où celui-ci a cessé ses cours.
                            </p>

                            <h3>Castillan</h3>
                            <p>
                                À Zignau et pendant longtemps à Roveredo, notre père s’est assez
                                systématiquement adressé à nous en castillan dans quelques genres de
                                situation très ciblés, volontairement toujours les mêmes: manger,
                                faim, dormir, promenade. Son but n’était pas de nous apprendre la
                                langue, mais de nous faire prendre conscience d’elle, de nous en faire
                                connaître la mélodie et la prononciation, et de créer ainsi un petit
                                point d’appui utile pour le cas où nous déciderions plus tard de
                                l’étudier.
                            </p>
                            <p>
                                Il a également tenté quelques expériences pour nous illustrer
                                combien, avec nos langues latines et les quelques rudiments que nous
                                avions acquis, nous comprenions déjà le castillan. Je me souviens que,
                                avec peu d’aide de sa part, nous avons ainsi, lors d’une promenade,
                                lu et compris la <i>Canción desesperada</i> de Neruda.
                            </p>
                            <p>
                                À l’adolescence, nous avons finalement commencé à étudier un peu
                                de castillan avec du matériel français du même genre que pour
                                l’allemand. Nous l’avons fait parallèlement à l’étude du
                                portugais, que nous poursuivions, en comparant les deux langues. Mais
                                cette étude, entreprise tard, n’a pas été très loin. L’été de
                                mes dix-huit ans, je n’en ai pas moins été en mesure de remplacer
                                notre père pour représenter une fondation romanche à un vaste
                                congrès académico-politique qui se tenait en Aragon; en plus d’y
                                discuter en français, italien, allemand et castillan avec des
                                participants beaucoup plus âgés et d’une toute autre position que
                                moi, j’y ai également pris la parole pour lire un texte en castillan
                                et en romanche.
                            </p>
                            <p>
                                Mon frère quant à lui a choisi le castillan comme troisième langue
                                étrangère (par rapport à l’italien langue d’enseignement) au
                                gymnase, et a fait ainsi quatre ans d’étude poussée de cette langue.
                            </p>


                            <h3>Anglais</h3>
                            <p>
                                Non, notre père ne nous a pas enseigné l’anglais. Nous avons au
                                contraire su très tôt qu’il souhaite que cette langue ne gagne pas
                                encore plus de place qu’elle n’en a déjà, et ne serve pas
                                d’oreiller de paresse pour éviter d’apprendre toute autre langue.
                                Notre père considérait que, d’une façon ou d’une autre, nous
                                apprendrions suffisamment l’anglais sans son intervention. Tout au
                                plus nous a-t-il introduit un peu la langue, toujours sur la base de
                                matériel scolaire français, juste avant que nous n’en commencions
                                l’étude comme troisième langue étrangère à l’école.
                            </p>
                            <p>
                                Mon frère a eu au total sept ans d’anglais, contre quatre pour moi
                                puisque je n’en ai commencé l’étude qu’au gymnase, donc à
                                l’âge de seize ans. Dans les études de sciences économiques que
                                j’entreprends à présent à Lugano, l’anglais tient une certaine
                                place et des cours seront même donnés dans cette langue.
                            </p>

                            <h3>Autres langues</h3>
                            <p>
                                Comme pour le castillan, mais dans une mesure encore beaucoup plus
                                limitée, notre père fait usage de certaines phrases ou formules
                                choisies en gaélique irlandais (qu’il a étudié, mais n’a plus
                                l’occasion de pratiquer), et de mots ou expressions en malais (qu’il
                                a un peu appris au travail dans sa jeunesse) et en arabe égyptien (dont
                                il n’a glané que quelques phrases). Son but a été uniquement de
                                nous faire prendre conscience de ce qu’il existe une multitude de
                                langues, et de nous donner des exemples précis qui en illustrent
                                quelques-unes, le point particulier étant qu’il ne s’est pas
                                contenté de citer une fois quelque chose en nous le laissant oublier
                                ensuite.
                            </p>
                            <p>
                                Le choix du gaélique a été en partie arbitraire, par sympathie pour
                                cette langue peu connue, mais notre père s’est tout de même inspiré
                                de l’exemple de sa logeuse à Baile Átha Cliath – autrement dit à
                                Dublin – qui, ne parlant par ailleurs qu’anglais à ses enfants, ne
                                leur disait jamais «Shut the door please», mais toujours «Dhún an
                                doras le do thoil». Il y a ainsi quelques phrases comme celle-là dont
                                nous savons que c’est du gaélique et que nous comprenons. Nous savons
                                aussi remercier, saluer ou souhaiter la bonne nuit en gaélique. Je sais
                                en outre que la prononciation courante du nom indigène de la capitale
                                de l’Irlande est à peu près «blâ-k’lia» – j’ai pu
                                discrètement corriger mon enseignant à ce sujet lorsque nous sommes
                                allés en voyage de classe en Irlande. Sans insister, comme curiosité,
                                notre père nous a également donné à différentes reprises quelques
                                explications quant à la construction et au système orthographiques
                                très particuliers du gaélique.
                            </p>
                            <p>
                                Pour l’arabe et le malais, le choix à première vue étrange
                                s’explique par des relations que nous avons avec des personnes parlant
                                ces langues. Ici aussi, mon frère et moi serions en principe capables
                                de saluer, remercier ou souhaiter bon appétit, à ceci près que nous
                                ne sommes pas nécessairement très sûrs si «lâfouanne» est «de
                                rien» en arabe ou en malais.
                            </p>

                            <h3>Prononciation</h3>
                            <p>
                                Nos langues de base nous ont permis d’apprendre à prononcer
                                correctement un très grand nombre de sons. Les nasales du polonais
                                (plus que celles du français) sont utiles pour le portugais. Grâce au
                                romanche, nous savons prononcer plus de chuintantes et de sifflantes que
                                les Polonais eux-mêmes, pourtant spécialistes en la matière. Le <i>z</i> du
                                castillan (avec le <i>ð</i> et le <i>þ</i> de l’islandais que notre père nous a
                                appris à prononcer) sert pour l’anglais. Alors que notre mère, comme
                                la plupart des Polonais, ne parvient pas à prononcer correctement le <i>u</i>
                                français ou le <i>ö</i> allemand, nous rendons clairement toutes les voyelles
                                de nos langues, y compris, en français, la différence essentielle
                                entre «l’ami aimé» et «l’amie aimée» ou entre «je pourrai»
                                et «je pourrais», que bien des «Francés» sont apparemment
                                incapables de distinguer. Depuis des années, nous tentons également,
                                sans grand succès, d’aider notre père à une prononciation correcte
                                du y polonais. Par contre, le système vocalique anglais est largement
                                étranger à notre éventail, et nous avons dû constater qu’il ne
                                faut pas compter sur l’école pour nous l’enseigner correctement.
                            </p>
                            <p>
                                Le <i>r</i> est un cas particulier. Pendant très longtemps, j’ai été –
                                comme notre père d’ailleurs – incapable de produire autre chose que
                                le <i>r</i> grasseyé du français. Notre père tentait de me tranquilliser en
                                me répétant qu’une minorité d’italophones de naissance, en Suisse
                                comme en Italie, ont eux aussi le <i>r</i> grasseyé – ainsi une
                                présentatrice connue de la TSI. Mais mon <i>r</i> grasseyé me dérangeait en
                                italien. Ce n’est que depuis quelques années que je parviens à
                                rouler correctement les <i>r</i>. Je me sers ainsi du <i>r</i> grasseyé en français,
                                du <i>r</i> roulé en polonais, italien, romanche et allemand, et je peux sans
                                autre grasseyer le <i>r</i> d’un nom français au milieu d’un texte italien
                                où je roule les <i>r</i> – ou faire l’inverse. Mon frère quant à lui
                                maîtrise moins bien le <i>r</i> roulé – mais ne s’en émeut pas.
                            </p>



                            <h1>4 Illustrations</h1>
                            <p>
                                Quelques indications et anecdotes, pour la plupart telles que nos
                                parents nous les ont racontées plus tard, peuvent servir
                                d’illustration un peu à la manière d’éléments d’une mosaïque.
                            </p>
                            <p>
                                Mon frère et moi avons commencé à «parler» légèrement plus tard
                                que d’autres enfants, mais à un âge encore dans la norme. Mon
                                premier mot reconnaissable a été «krrem», avec un <i>r</i> longuement
                                grasseyé, un mot qui, le <i>r</i> mis à part, peut être aussi bien polonais
                                que français. Pendant un certain temps, aussi bien mon frère que moi
                                avons utilisé des mots isolés, souvent déformés, tirés de l’une
                                ou l’autre des langues que nous entendions. Un objet avait en
                                général un seul nom. Quand nous avons commencé à composer des
                                phrases, nous mêlions encore les langues de telle sorte que seuls nos
                                parents parvenaient à nous comprendre. Pour parler de moi-même, j’ai
                                ainsi dit pendant longtemps «toi», même dans des phrases par ailleurs
                                polonaises. Mais nous avons assez rapidement commencé à différencier
                                nos expressions suivant l’interlocuteur. Vers trois ans, nous parlions
                                essentiellement polonais avec notre mère, essentiellement français
                                avec nos grands-parents suisses, même si les mots et la syntaxe
                                restaient encore difficilement compréhensibles.
                            </p>
                            <p>
                                À âge égal, selon les notes prises à l’époque et qui permettent
                                la comparaison, mon frère et moi avons cependant eu pendant les cinq à
                                six premières années de notre vie une attitude un peu différente
                                vis-à-vis de la diversité des langues. J’ai distingué plus tôt et
                                de manière plus systématique que mon frère les langues que je
                                parlais. Je donnais l’impression de mieux comprendre et mieux accepter
                                la multiplicité des langues et des interlocuteurs, de faire plus
                                d’efforts pour chercher un mot dans la langue qui convenait. Mon
                                frère avait tendance à rechercher un peu la facilité, ou
                                s’abstenait de s’exprimer.
                            </p>
                            <p>
                                À Zignau, nous étions en tout et pour tout trois enfants. Alors
                                qu’à trois ans je jouais en romanche (dans la mesure où l’on joue
                                alors dans une langue) avec notre voisin de mon âge, mon frère,
                                arrivé au même âge, me laissait encore tout le soin de la
                                conversation, me demandant éventuellement, dans un mélange que je
                                comprenais, de dire pour lui ce qu’il voulait faire savoir.
                            </p>
                            <p>
                                La même chose s’est reproduite au début à Roveredo avec
                                l’italien. Quand nous jouions avec les voisins, il comptait fortement
                                sur moi, et, à l’«asilo nido», il ne disait pas un mot de tout
                                l’après-midi.
                            </p>
                            <p>
                                À l’inverse, quand il adoptait une nouvelle langue active, il fallait
                                faire attention à ce qu’il ne néglige pas les précédentes. Ainsi
                                notre mère a-t-elle dû à plusieurs reprises faire beaucoup
                                d’efforts pour qu’il ne cesse pas de lui parler en polonais (alors
                                que je n’ai paraît-il eu qu’une seule – et brève – période
                                semblable, tout au début de ma vie).
                            </p>
                            <p>
                                Au total cependant, grâce à la vigilance et à l’aide de nos
                                parents, les choses se sont décantées, les structures se sont mises en
                                place. À cinq ans, en tout cas, j’ai fait une excursion avec mes
                                grands-parents suisses et mon grand-père polonais, et la communication
                                entre eux reposait exclusivement sur mes capacités de traducteur. Je
                                suis parvenu à l’assurer pour les choses de mon âge (mais ma
                                grand-mère a ensuite reproché à mon père que je n’avais pas
                                traduit les explications qu’elle voulait donner à mon grand-père
                                polonais quant au style gothique d’une église …).
                            </p>
                            <p>
                                Quand nous voyions une personne pour la première fois, nous attendions
                                un moment pour identifier son appartenance linguistique et, une fois
                                celle-ci déterminée, nous nous y tenions. Dans un cas cependant, nous
                                avions attribué l’étiquette romanche à un visiteur fréquent, par
                                ailleurs germanophone, parce qu’il parlait romanche avec nos deux
                                parents ensemble; mais ce contact parle aussi polonais, et quand nous
                                l’avons découvert parce que, un jour où notre père était absent,
                                notre mère l’avait salué en polonais, nous n’avons pendant
                                longtemps plus parlé que polonais avec lui (aujourd’hui, c’est la
                                situation qui détermine la langue dans laquelle je parlerai avec lui:
                                romanche si nous sommes avec des Romanches, polonais s’il est avec son
                                épouse polonaise).
                            </p>
                            <p>
                                Un exemple isolé n’en est pas moins caractéristique. Un ami ladin
                                dolomitique est venu chez nous quand j’avais environ quatre ans. Je
                                l’avais déjà vu plus de deux ans auparavant, mais je ne pouvais pas
                                m’en souvenir. Par commodité, la communication avec lui se fait en
                                allemand ou en italien. Il savait que je ne parlais pas allemand, et que
                                notre père me parlait en partie en italien. Ne pratiquant pas
                                suffisamment le romanche, il s’est adressé à moi en italien. À ce
                                qu’il paraît, je l’ai longuement dévisagé, réfléchissant
                                comment lui répondre. Je ne parlais pas italien, ou pas assez pour lui
                                répondre. Arrivé apparemment à la conclusion que c’était là la
                                langue la plus proche de celle que le visiteur utilisait, je lui ai
                                répondu en romanche.
                            </p>
                            <p>
                                Je viens d’écrire que, en Surselva, je ne parlais pas italien.
                                J’employais cependant des mots italiens. Un des premiers objets que
                                j’aie désignés nommément a été la lune, et je l’ai appelée
                                «luna», pas «lune», ni «glina», et encore moins «księżyc».
                                J’ai également créé un jeu qui consistait à «parler italien» en
                                ajoutant les terminaisons <i>-o</i> ou <i>-a</i> à des mots français ou romanches. Et
                                quand j’ai su que, quelques jours plus tard, nous allions partir
                                habiter «là où on parle italien», j’ai passé de longs moments à
                                jouer avec qui voulait bien au «magasin italien»: j’étais le
                                vendeur, et je proposais mes marchandises en m’efforçant de donner
                                une apparence italienne à ce que je disais. Mon frère était avec moi
                                et disait «Sì, sì».
                            </p>
                            <p>
                                Nous étions très conscients de la langue que chacun devait utiliser
                                vis-à-vis de nous, ou en tout cas je l’étais. Seul notre père avait
                                le droit de s’adresser à moi en des langues différentes. Je
                                n’avais pas encore trois ans quand mes grands-parents suisses, en
                                visite chez nous, nous ont emmenés, mon père, mon grand-père polonais
                                et moi, en excursion à Chiavenna. Ce devait être mon premier contact
                                avec l’aire italophone. Dans la voiture, mon père m’y a préparé
                                en m’expliquant en italien que, là-bas, tout le monde parle italien.
                                Ma grand-mère a alors voulu me parler elle aussi en italien. Je l’ai
                                arrêtée d’un «Na! Tita parle!» catégorique, signifiant que mon
                                père seul avait le droit de parler italien avec moi.
                            </p>
                            <p>
                                Dans ma petite enfance, je parlais alternativement français et romanche
                                avec mon père. Nous jouions à changer de langue. Mon père a conservé
                                une certaine nostalgie de cette époque révolue.
                            </p>
                            <p>
                                Nous avons également assimilé rapidement que les langues ont des
                                territoires.
                            </p>
                            <p>
                                Mon frère n’avait pas huit ans quand il a participé au championnat
                                grison de judo à Mustér, en Surselva romanche, comme membre du club de
                                Roveredo. Pour comprendre l’anecdote qui suit, il faut se souvenir,
                                premièrement, que les Alémaniques sont habitués à traiter les
                                Romanches comme s’ils étaient des Alémaniques, c’est-à-dire
                                qu’ils attendent d’eux qu’ils leur parlent dans leur dialecte
                                alémanique; et, deuxièmement, que les vallées italophones n’ont
                                qu’un poids démographique négligeable dans le canton.
                            </p>
                            <p>
                                Pendant les combats, nous avons vu de la tribune que l’arbitre a
                                plusieurs fois fait des remarques à mon frère, et que celui-ci lui
                                répondait tranquillement quelque chose en levant la tête vers lui.
                                L’arbitre s’est énervé, et a fini par mal se comporter lors d’un
                                autre combat auquel participait un camarade de Roveredo, ce qui a causé
                                un petit scandale. Quand nous avons demandé ensuite à mon frère ce
                                qui s’était passé, il a expliqué que le Monsieur lui avait parlé
                                plusieurs fois dans une langue qu’il ne comprenait pas, et que,
                                puisqu’on était à Mustér, il lui avait à chaque fois répondu en
                                romanche «Jeu capeschel buc tei» (Je ne te comprends pas). À ce qui
                                nous a été expliqué par la suite, l’arbitre, zurichois, ignorait
                                que tous les enfants des Grisons ne parlent pas alémanique, et
                                s’était laissé déstabiliser par un enfant qui lui répondait
                                crânement dans une langue que lui, l’arbitre, ne comprenait pas.
                            </p>
                            <p>
                                Surtout dans notre enfance, notre plurilinguisme a parfois attiré
                                l’attention. Nos parents n’ont pas recherché la publicité, mais
                                – à chaque fois dans la mesure où mon frère et moi étions
                                d’accord – ils ont accepté quelques occasions qui se sont
                                présentées de faire passer un message:
                            </p>
                            <p className="quote">
                                Le plurilinguisme précoce est fréquent et naturel dans notre société
                                marquée par la mobilité et le mélange; il n’est pas une charge pour
                                l’enfant, et lui apporte au contraire une richesse particulière pour
                                la vie. Mais il lui faut des conditions de base pour se maintenir et se
                                développer. Or, la société ne fait pas ce qu’il faut en la
                                matière. Le système scolaire tel qu’il est conçu tend même à
                                détruire les plurilinguismes qui existent – avant d’essayer
                                d’introduire des langues <b>étrangères</b> de manière non naturelle et
                                très inefficace.
                            </p>
                            <p>
                                Un grand article avec photos à l’appui est paru en français et en
                                allemand dans deux des principaux titres de la presse suisse, donnant
                                justement en substance ce message.
                            </p>
                            <p>
                                Ma marraine journaliste à la radio romanche a construit une émission
                                sur le plurilinguisme en me faisant intervenir comme enfant quadrilingue
                                de neuf ans et en me faisant passer devant le micro une sorte de petit
                                examen de traduction du romanche en français, polonais et italien.
                            </p>
                            <p>
                                Indépendamment l’un de l’autre, un journaliste romanche et un
                                journaliste alémanique, venus pour illustrer les travaux de notre père
                                à l’intention de leurs télévisions respectives, ont demandé à
                                ajouter un volet en nous filmant mon frère et moi dans notre élément.
                            </p>
                            <p>
                                Les réactions admiratives de certaines personnes et ces quelques
                                exemples d’intérêt de la presse nous ont donné une certaine
                                fierté, bien sûr, mais nous ont en même temps étonné, et parfois
                                aussi un peu embarrassés, voire ennuyés. De fait, notre plurilinguisme
                                était et est pour nous naturel. Nous en sommes conscients, et en tirons
                                satisfaction, mais pas de vanité. La meilleure illustration en est
                                peut-être l’attitude de mon frère à douze ans à peine devant une
                                équipe de la TSI, la Télévision suisse de langue italienne, venue à
                                Rumein couvrir la présentation du dictionnaire romanche-français de
                                notre père. Ayant appris par ses collègues romanches que mon frère,
                                présent à la cérémonie, parlait déjà plusieurs langues, la
                                journaliste a demandé à l’interviewer. Mon frère a accepté avec
                                calme, et on a pu le voir le soir aux nouvelles réfléchir à la
                                question «Tu parli... quante lingue?», compter mentalement, et
                                répondre avec sérieux, sans aucune forfanterie, en se trompant même
                                en sa défaveur dans le nombre de ses langues: «Beh, in minimo cinque.
                                Certe cose, certe paroline in altre lingue le so, però se no, cinque,
                                diciamo.» Puis il a encore répondu qu’il apprenait le portugais, et,
                                aussitôt libéré, a à peu près cessé de penser à cet interview.
                            </p>



                            <h1>5 Résultat</h1>

                            <h2>5.a État des connaissances</h2>

                            <h3>Polonais</h3>
                            <p>
                                Pour ce qui est du niveau passif, je pense que je comprends le polonais
                                courant pratiquement aussi bien qu’un Polonais habitant en Pologne.
                            </p>
                            <p>
                                En ce qui concerne l’expression orale, je n’ai pas de difficultés
                                pour la langue courante. Par contre, dès qu’on entre dans un domaine
                                précis, je sens les différents manques que j’ai dans ma langue
                                maternelle. Comme je n’ai pas pu approfondir suffisamment mon
                                vocabulaire, et n’ai guère le temps, dans une discussion, de chercher
                                un mot dans un dictionnaire, je suis bien souvent obligé, parlant avec
                                notre mère, d’insérer des mots étrangers, en les déclinant
                                éventuellement à la polonaise, pour lui expliquer par exemple ce que
                                j’ai appris à l’école – ou, aujourd’hui, à l’université.
                                Il faut toutefois remarquer que même notre mère n’a bien souvent
                                aucune idée de comment dire telle ou telle chose en polonais (ce qui
                                s’explique certainement en partie par le manque de pratique de la
                                langue dont, à un certain niveau, elle souffre elle-même). En
                                général, je peux dire que je fais plus d’efforts que mon frère pour
                                chercher le mot polonais exact, ou bien un mot similaire.
                                Paradoxalement, c’est parfois notre père qui nous souffle un mot
                                polonais – mais il s’adresse au moins aussi souvent à nous pour
                                nous demander l’équivalent polonais d’un mot français.
                            </p>
                            <p>
                                Au niveau de l’écrit, les leçons faites avec notre mère, même
                                quotidiennement pendant des années, ne m’ont pas permis d’arriver
                                à une maîtrise parfaite du polonais (en fait, à bien y regarder, cela
                                vaut à des degrés divers pour chacune de mes langues, mais ce qui
                                compte est d’avoir un bon niveau dans le plus grand nombre possible de
                                langues). Malgré tout, même si je fais des fautes d’orthographe ou
                                de grammaire, je réussis assez régulièrement à prouver à mes
                                parents que j’ai (plus que) de bonnes connaissances du polonais
                                écrit.
                            </p>
                            <p>
                                Un bon exemple de l’«effort» que je fais pour communiquer en
                                polonais avec notre mère est celui des textos. Quand je dois écrire un
                                message à notre mère, j’emploie le polonais (notre mère ne se sert
                                elle aussi que du polonais avec mon frère et moi – notre père n’a
                                pas de téléphone mobile). Mon frère, lui, emploie l’italien dans
                                ses messages à notre mère. L’excuse qu’il donne est qu’il
                                utilise la langue automatique italienne. Personnellement, je le
                                réprouve fortement et je trouve que c’est une erreur de sa part,
                                parce que les occasions de parler et surtout d’écrire le polonais (à
                                part les quelques discussions qu’il a sur internet) sont rares.
                            </p>
                            <p>
                                Soit dit en passant, j’ai également remarqué combien souvent et
                                facilement mon frère a recours à des mots «polonisés» ou carrément
                                inventés (par exemple «z-inwentować»). Mais la faute, ici, n’est
                                pas seulement la sienne. Je trouve en effet que notre mère, pour sa
                                part, se limite trop à seulement écouter sans corriger, sans glisser
                                le mot manquant, sans faire, en fin de compte, l’effort que l’on
                                pourrait exiger d’elle au nom d’un devoir moral.
                            </p>
                            <p>
                                Au niveau de la lecture, je constate que j’ai de grandes difficultés
                                à lire rapidement. La raison première en est tout simplement que je
                                lis très peu en polonais, ce qui à son tour s’explique par le fait
                                que, d’une manière générale, je ne lis pas volontiers, entre autres
                                parce que l’université prend trop de place pour que je me mette
                                encore à lire d’autres livres que ceux qu’il me faut pour mes
                                études: je préfère m’occuper autrement pendant le peu de temps
                                libre qui me reste.
                            </p>

                            <h3>Français</h3>
                            <p>
                                Le français est sans aucun doute, avec l’italien, la langue que
                                j’ai le plus et le mieux développée. Je comprends sans autre
                                absolument tout ce qui concerne les thèmes un tant soit peu habituels.
                                Au début de mes études en Suisse romande, j’ai eu un léger
                                sentiment d’étrangeté à suivre des cours en français mais,
                                fondamentalement, j’étais au niveau des étudiants ayant derrière
                                eux treize ou quinze ans d’école française. Je découvre bien sûr
                                régulièrement des mots nouveaux, mais il en va de même en italien –
                                dans les domaines qui n’ont pas été couverts par l’école, mon
                                vocabulaire passif est même plutôt plus étendu en français.
                            </p>
                            <p>
                                Je m’exprime sans aucune difficulté, en ce sens que je ne dois pas
                                réfléchir avant de parler. Paradoxalement, sachant que, après quinze
                                ans de scolarité italienne, j’ai parfois une influence ponctuelle de
                                l’italien sur mon français, il m’arrive d’hésiter à employer
                                une expression par crainte que ce soit un italianisme. Ainsi, parlant
                                récemment avec mon père, j’ai cité l’allemand «auf Messers
                                Schneide» par crainte que «sur le fil du rasoir» ne soit un calque
                                de l’italien «sul filo del rasoio». Je sais maintenant que
                                l’expression est (aussi) parfaitement française.
                            </p>
                            <p>
                                En ce qui concerne la lecture, grâce au fait que, comme mon frère,
                                j’ai pas mal lu (ou été d’une certaine manière obligé à le
                                faire) dans mon enfance et mon adolescence, je lis assez rapidement et
                                sans difficulté.
                            </p>
                            <p>
                                Pour ce qui est de l’écriture, je n’ai pas non plus de grandes
                                difficultés en ce qui concerne la langue elle-même, c’est-à-dire
                                l’orthographe ou la grammaire. D’après les comparaisons que j’ai
                                pu faire, j’ai même plus de sûreté que la majorité des jeunes
                                francophones de mon âge. Là où je ressens un manque et sais avoir de
                                la peine, c’est en matière de style. Je traîne là un véritable
                                boulet (le présent témoignage, révisé par la personne que l’on
                                peut imaginer, n’est pas représentatif de ma production habituelle).
                                Une raison de ce manque est certainement que je n’ai de loin pas lu
                                autant de livres que mes parents. Mais une deuxième raison est,
                                simplement et malheureusement, le fait que l’école, que ce soit
                                l’école primaire ou secondaire et même le gymnase, n’a pas
                                vraiment soigné l’enseignement du style et de la rédaction.
                            </p>
                            <p>
                                Cette situation générale est également celle de mon frère, qui vient
                                de commencer, de façon tout à fait normale du point de vue
                                linguistique, des études de biologie à l’Université de Lausanne.
                            </p>

                            <h3>Italien</h3>
                            <p>
                                Comme l’italien est la langue dans laquelle j’ai fait toute ma
                                scolarité jusqu’à la maturité, je n’ai de difficulté à aucun
                                des niveaux de connaissance de la langue, sauf bien sûr en ce qui
                                concerne le style et la capacité rédactionnelle. Mon frère est dans
                                la même situation que moi.
                            </p>
                            <p>
                                De façon caractéristique, nous pouvons tous deux aider notre père
                                dans ses travaux en italien pour lui donner des tournures courantes ou
                                pour dissiper des doutes, par exemple quant à l’emploi du subjonctif;
                                mais, tous deux, nous avons à maintes reprises eu recours à son aide
                                d’italianisant pour rédiger des travaux scolaires en italien – un
                                paradoxe qui suffit à illustrer les lacunes de l’école dans ce
                                domaine.
                            </p>

                            <h3>Dialecte mesolcinais</h3>
                            <p>
                                Mon frère et moi avons appris à comprendre le dialecte de la
                                Basse-Mesolcina et, sur cette base, nous comprenons également les
                                dialectes de la plus grande partie de la Suisse italienne. Fût-ce sous
                                une forme influencée par le standard, nous serions également en mesure
                                de le parler couramment avec les locuteurs d’ici, voire des régions
                                avoisinantes du Tessin, mais la barrière psychologique nous a toujours
                                empêchés de le faire.
                            </p>

                            <h3>Romanche</h3>
                            <p>
                                Je suis fier de pouvoir affirmer me débrouiller plutôt assez bien en
                                romanche si l’on tient compte du peu d’occasions que j’ai de
                                pratiquer cette langue, et mon père est souvent surpris en bien de mes
                                connaissances.
                            </p>
                            <p>
                                Comme je l’ai mentionné ci-dessus, je comprends non seulement mon
                                idiome sursilvan, mais également les autres formes de la langue. J’ai
                                récemment été très étonné – et bien sûr fier – de constater
                                que je suis capable de lire et traduire en sursilvan une transcription
                                du dialecte, extrêmement particulier, de Bravuogn.
                            </p>
                            <p>
                                À l’oral, je m’entretiens avec facilité avec les Romanches de tous
                                les idiomes, même si je dois souvent chercher un mot. Et si je fais des
                                fautes de grammaire, je sais que ma prononciation montre que je suis
                                (aussi) «de langue romanche», et je parle un romanche moins germanisé
                                que celui de la majorité des Romanches.
                            </p>
                            <p>
                                C’est à l’écrit que j’aurai le plus de problèmes; mais, ici
                                aussi, j’ai constaté que j’orthographie plutôt mieux le romanche
                                (sursilvan) que bien des Sursilvans de mon âge.
                            </p>
                            <p>
                                Dans l’ensemble, le tableau vaut aussi pour mon frère, à ceci près
                                qu’il a encore moins de pratique active que moi, et qu’il a,
                                peut-être, moins d’intérêt et de sentiment que moi pour cette
                                langue – mais l’indifférence qu’il affiche parfois pourrait aussi
                                être de façade.
                            </p>

                            <h3>Allemand</h3>
                            <p>
                                Au niveau de la compréhension, je me tiens au courant en regardant des
                                chaînes d’Allemagne pendant la plus grande partie du temps que je
                                passe devant la télévision. Les différents séjours que j’ai faits
                                dans la famille de mon parrain à Berlin m’ont également beaucoup
                                aidé pour l’oral et notamment pour la mélodie de la langue. Je me
                                suis sensiblement amélioré en lecture ces derniers temps parce que
                                j’ai fait un effort pour lire quelques classiques, tous en allemand
                                (Thomas Mann, Hans Albrecht Moser).
                            </p>
                            <p>
                                C’est à l’écrit que je rencontre le plus de difficultés, parce
                                que, depuis ma sortie du gymnase, les occasions d’écrire l’allemand
                                sont plutôt rares.
                            </p>
                            <p>
                                Pour différentes raisons, en premier lieu parce qu’il n’a fait que
                                tout récemment un premier séjour linguistique dans un milieu
                                germanophone, mais aussi parce qu’il a étudié de manière
                                différente, moins indépendante que moi, mon frère, s’il connaît
                                très bien la grammaire allemande, et comprend la langue à peu près
                                aussi bien que moi, n’a pas développé son vocabulaire actif autant
                                que moi.
                            </p>

                            <h3>Portugais</h3>
                            <p>
                                Je n’ai presque plus pratiqué le portugais depuis cinq ans. Malgré
                                cela, j’en conserve une compréhension passive non négligeable à
                                l’écrit comme à l’oral, bien supérieure en tout cas à ce
                                qu’apporte la connaissance approfondie de deux autres langues latines.
                                En ce qui concerne ma capacité à m’exprimer, elle est ou serait
                                extrêmement réduite, mais elle se développerait notablement au cours
                                d’une seule discussion.
                            </p>
                            <p>
                                La situation est comparable pour mon frère, qui aurait cependant à la
                                fois l’avantage et le handicap de ses quatre ans de castillan au
                                gymnase.
                            </p>

                            <h3>Castillan</h3>
                            <p>
                                Mes capacités en castillan sont assez comparables à celles que j’ai
                                en portugais, la différence étant, d’une part, que ma base
                                structurée est beaucoup plus réduite qu’en portugais, ce qui me
                                freine, mais d’autre part, que le castillan déroute moins que le
                                portugais et, surtout, est plus présent dans le monde. Par ailleurs, le
                                fait que notre père nous parle un peu dans cette langue (alors qu’il
                                est très rare qu’il le fasse en portugais) a sans doute un certain
                                effet.
                            </p>
                            <p>
                                Ici, mon frère est dans une toute autre situation, puisqu’il a eu
                                quatre ans d’étude du castillan au gymnase et que, contrairement à
                                moi, il a lu plusieurs livres entiers dans cette langue. Il est
                                réellement capable de s’exprimer couramment en castillan. Mais il lui
                                faudra maintenant faire attention à trouver des occasions de continuer
                                à pratiquer cette langue.
                            </p>

                            <h3>Anglais</h3>
                            <p>
                                En anglais, j’ai essentiellement les connaissances que m’ont
                                apportées quatre ans de gymnase, ainsi que l’écoute continue de
                                chansons en anglais (mais j’écoute aussi beaucoup de chansons en
                                polonais, allemand, français et italien). J’ai donc plus de facilité
                                à l’écoute et à la compréhension. Cela vaut aussi pour mon frère.
                            </p>
                            <p>
                                En ce qui concerne l’écrit, maintenant que j’ai des cours
                                d’anglais à l’université, je constate que je m’en sors assez
                                bien. Pour l’expression orale, je regrette que l’enseignement
                                scolaire n’a pas apporté grand-chose en fait de prononciation, et que
                                je n’ai pas pu acquérir un accent britannique. Comme notre
                                professeure d’anglais économique à l’université nous parle avec
                                un tel accent et le souligne, j’espère combler un peu cette lacune.
                            </p>

                            <h2>5.b Emploi des langues</h2>

                            <h3>En général</h3>
                            <p>
                                D’une façon générale, mon frère et moi nous servons sans
                                hésitation de la langue de notre interlocuteur si celui-ci parle une de
                                nos langues principales, soient les langues nationales et le polonais,
                                ainsi que l’anglais et, pour mon frère, le castillan. À l’inverse,
                                nous savons très bien jouer avec notre registre linguistique pour
                                exclure les personnes présentes, connues ou inconnues, si nous en
                                ressentons le besoin (mais nous ne devons jamais oublier que nous ne
                                sommes pas les seuls à parler nos langues et que, en particulier, il y
                                a plus de quarante millions de locuteurs du polonais dans le monde).
                                C’est un réflexe presque automatique, qui représente une
                                caractéristique accessoire, mais très réelle, de notre
                                plurilinguisme. Mon frère n’a peut-être jamais parlé autant
                                polonais avec moi ou avec notre père que depuis qu’il nous
                                téléphone de Lausanne.
                            </p>

                            <h3>Avec notre mère</h3>
                            <p>
                                Depuis longtemps, il est absolument exceptionnel que mon frère et moi
                                nous servions d’une autre langue que le polonais pour nous adresser à
                                notre mère. Le seul accroc à cet usage, mais il est systématique et
                                non négligeable, est l’emploi (voir ci-dessus) que mon frère fait de
                                l’italien dans ses textos à notre mère.
                            </p>

                            <h3>Avec notre père</h3>
                            <p>
                                Il est intéressant de noter que mon frère n’a jamais essayé, comme
                                il l’a fait avec notre mère et avec moi, de parler italien avec notre
                                père. La langue habituelle et normale de communication avec notre père
                                est, aussi bien pour mon frère que pour moi, le français. En présence
                                de notre mère, toutefois, nous parlons généralement polonais, et il
                                nous arrive également de parler dans cette langue avec notre père
                                quand il y a un lien avec notre mère. Le polonais est également la
                                langue que nous utilisons le plus souvent quand nous voulons ne pas
                                être compris par les personnes qui nous entourent, par exemple dans un
                                magasin, ou au téléphone.
                            </p>
                            <p>
                                Nous ne nous servons aujourd’hui qu’exceptionnellement et très
                                brièvement du romanche, et l’emploi d’autres langues ne se fait que
                                par jeu, ou en présence de personnes que nous ne voulons pas exclure.
                                Parler avec notre père une autre langue que le français nous semble
                                toujours bizarre s’il n’y a pas de raison précise pour le faire.
                            </p>
                            <p>
                                Notre père, lui, s’adresse à nous principalement en français. Il
                                nous parle toutefois très généralement polonais en présence de notre
                                mère. Comme cela nous arrive de notre côté avec lui, il se surprend
                                parfois à nous parler en polonais presque par inadvertance, lorsque
                                quelque chose fait penser à notre mère, ou au polonais.
                                Généralement, il passe alors au français après quelques phrases.
                                Comme nous, il se sert également du polonais comme langue d’exclusion
                                mais, sachant le nombre de polonophones dans le monde, il choisit
                                parfois le romanche s’il peut s’exprimer sans mots trop
                                reconnaissables pour des Latins.
                            </p>
                            <p>
                                Notre père ne nous parle plus autant romanche qu’autrefois, mais il
                                reste très naturel pour lui de nous dire quelque chose de bref, voire
                                de commencer une conversation dans cette langue; toutefois, nous lui
                                répondons presque toujours en français, et il nous suit rapidement
                                dans notre choix. De manière naturelle, ou d’une manière devenue
                                naturelle avec les années, il se sert parfois du castillan, voire, plus
                                rarement, du portugais pour une question ou une réplique. L’emploi de
                                quelques phrases stéréotypées d’autres langues, notamment du
                                gaélique, est une sorte d’automatisme.
                            </p>

                            <h3>Entre mon frère et moi</h3>
                            <p>
                                Mon frère et moi avons vingt-trois mois d’écart. C’est donc à moi
                                qu’est revenue la tâche de déterminer initialement en quelle(s)
                                langue(s) m’adresser à lui, et donc, probablement, en quelle(s)
                                langue(s) nous allions développer nos relations. Au début, d’après
                                les souvenirs et les notes de mes parents, je m’adressais à mon
                                frère, comme à tout le monde, dans un mélange de mes langues. Puis il
                                a semblé que je penchais vers le polonais, ce qui avait une certaine
                                logique du moment que, même si notre père était toujours présent à
                                la maison, je passais beaucoup plus de temps avec notre mère. De temps
                                en temps, j’ai également utilisé le romanche. Mais, encore à
                                Zignau, l’usage s’est établi entre nous de parler presque
                                exclusivement en français.
                            </p>
                            <p>
                                Après un an à Roveredo, lorsqu’il a commencé à se servir de
                                l’italien, mon frère a toutefois eu une forte tendance à me parler
                                dans cette langue, et ainsi à m’entraîner vers elle. Comme je l’ai
                                déjà mentionné, nos parents ont réagi de deux façons, en en
                                appelant à moi pour que je maintienne l’usage d’une autre langue
                                (ils me laissaient le choix, réellement, m’incitaient même à parler
                                (aussi) polonais mais, pour moi, il ne pouvait plus s’agir désormais
                                que du français), et en intervenant directement auprès de mon frère.
                            </p>
                            <p>
                                Nous avons ainsi conservé le français comme langue principale de
                                communication entre nous, le polonais ne nous servant presque plus que
                                pour communiquer sans être compris par d’autres personnes du monde
                                extérieur – sauf en Pologne, où nous nous servons évidemment soit
                                du français, soit de l’italien.
                            </p>
                            <p>
                                Plus tard encore, cependant, toujours plutôt à l’initiative de mon
                                frère, nous avons commencé à utiliser également l’italien dans
                                certaines situations, par exemple quand nous parlions à l’école, ou
                                lorsque le jeu électronique auquel nous jouions était en italien. Tant
                                que la part de l’italien restait relativement faible, nos parents ont
                                accepté cette situation, mais ils étaient vigilants, et nous sentions
                                leur vigilance. Il suffisait souvent que notre père s’adresse à nous
                                en français, voire simplement manifeste sa présence comme une sorte de
                                signal, pour nous faire repasser au français.
                            </p>
                            <p>
                                Au début de l’adolescence, nous avons également commencé à
                                pratiquer entre nous le dialecte local, en comptant un peu que, la
                                pratique et l’habitude venant, nous pourrions nous lancer à le parler
                                avec nos camarades dialectophones, deux tiers à trois quarts du total.
                                Comme je l’ai déjà mentionné, la situation sociolinguistique
                                régnante nous a empêchés de faire le pas. Pendant quelques années,
                                le dialecte, dans un débit rapide, nous a également servi dans une
                                certaine mesure pour rester entre nous deux par rapport à nos parents.
                                Nous comptions ici sur le fait que, outre qu’ils respectaient
                                certainement le désir d’intimité que cet emploi exprimait, ils
                                avaient eu peu l’occasion de se faire l’oreille au dialecte et que
                                notre père, le plus susceptible de le comprendre, était désormais
                                handicapé par sa perte d’ouïe. Ces dernières années, nous avons
                                plus ou moins abandonné la pratique du dialecte entre nous.
                            </p>
                            <p>
                                Aujourd’hui, je m’adresse essentiellement en français à mon
                                frère. Lui par contre, s’il utilise majoritairement le français avec
                                moi, a encore souvent tendance à me parler en italien. Je lui réponds
                                quant à moi assez systématiquement en français, et il m’arrive de
                                lui demander carrément de passer au français, parce que cela me
                                dérange de l’entendre me parler dans une autre langue.
                            </p>
                            <p>
                                Il y a toutefois quelques exceptions. La première est, comme toujours,
                                constituée par les cas où il s’agit d’exclure les personnes
                                susceptibles de nous comprendre. C’est en ce sens que, depuis qu’il
                                est à Lausanne, j’accepte sans autre que mon frère me parle en
                                polonais ou en italien au téléphone afin d’éviter que sa logeuse ou
                                d’autres personnes, si elle l’entendent, le comprennent; mais, pour
                                ma part, je lui réponds souvent en français, sauf pour certains jeux,
                                ou certaines fois où nous parlons de filles.
                            </p>
                            <p>
                                Il sera intéressant de voir si le fait d’habiter en Romandie
                                influencera peu à peu mon frère dans son emploi des langues
                                lorsqu’il parle avec moi.
                            </p>
                            <p>
                                En ce qui me concerne, même si (pour des raisons n’ayant rien à voir
                                avec la langue) je suis revenu en Suisse italienne, je ne pense pas que
                                je développerai une tendance à me servir davantage de l’italien avec
                                ma famille.
                            </p>

                            <h3>Langue(s) familiale(s)</h3>
                            <p>
                                Dans la mesure où nous les maîtrisons, nos parents et nous utilisons
                                nos langues principales dans différents registres allant, suivant la
                                situation, du langage familier à la langue recherchée. Tous, nous
                                parlons un français à forte coloration romande, tandis qu’en
                                allemand nous nous démarquons autant que possible du «Schweizer
                                Hochdeutsch». En italien, mon frère et moi parlons évidemment le
                                standard de Suisse italienne.
                            </p>
                            <p>
                                Sauf éventuellement par jeu, et à part les mots que, d’un accord
                                tacite, nous introduisons par commodité pour ne pas perdre de temps, ou
                                les mots de la «langue familiale» que je décris ci-dessous, nous ne
                                mêlons pas les langues lorsque nous parlons entre nous. Nous pouvons
                                toutefois fort bien passer sans transition d’une langue à l’autre
                                suivant la situation.
                            </p>
                            <p>
                                Lorsque nous les parlons entre nous, toutes nos langues, et surtout bien
                                sûr le français et le polonais, ont cependant quelques aspects ou
                                éléments spécifiques à la famille – ou parfois à deux ou trois
                                d’entre nous –, qui seront souvent hermétiques à tout observateur
                                extérieur. Il s’agit entre autres d’allusions à quelque chose de
                                passé, de jeux de mots, de déformations volontaires ou non, de
                                raccourcis, d’automatismes ou de stéréotypes divers. Ce sera là
                                certainement un trait commun à bien des familles, mais, chez nous, il
                                s’y ajoute le facteur du multilinguisme.
                            </p>
                            <p>
                                Qui pourrait reconstituer que, si notre mère dit «fédération!»
                                lorsque notre père éternue, cela vient du romanche «viva!» qui,
                                prononcé à l’allemande, donne FIFA, Fédération internationale de
                                football amateur: par jeu, nos parents ont eu employé l’expression
                                entière, et notre mère y fait encore allusion en n’en donnant plus
                                que le premier élément. Si notre père nous souhaite bon appétit en
                                malais, notre mère et moi avons pris le pli de répondre «la paix soit
                                avec toi» en arabe, parce que «salam» ressemble à «selamat» –
                                mon frère, qui aime les sorties volontairement un peu absurdes, répond
                                quant à lui généralement «bonjour» en croate. De mon côté, je
                                m’amuse parfois à utiliser avec notre père les rares expressions
                                qu’il a encore apprises, et qu’il nous a transmises, du dialecte
                                aujourd’hui à peu près disparu de l’Ajoie.
                            </p>
                            <p>
                                Quelle que soit la langue que nous utilisons, le tue-mouches est
                                toujours le «mazzamuostgas», le salon ou la pièce de séjour la
                                «stiva» (qui se décline en polonais comme n’importe quel féminin
                                se terminant en a). Les reuchtis/röstis restent tels quels, ce qui est
                                assez normal en Suisse. Mais ce qui peut être plus spécifique, c’est
                                que le rôti haché a donné le «rotjasz», tandis que, en français,
                                mon frère et moi distinguons soigneusement les côtelettes et les
                                «kotlety», qui sont des boules aplaties de viande hachée rôties dans
                                la poêle. Il va d’ailleurs de soi que les mets polonais ont conservé
                                leur nom original, barszcz, gołąbki, pierogi, uszki, etc. «Po
                                malezyjsku», par contre, fait allusion à un plat que notre mère a
                                appris d’un ami indo-malaisien.
                            </p>
                            <p>
                                Pour terminer par un dernier exemple, qui pourrait deviner que le nom
                                que mon frère et moi donnons à notre père vient de «tatuś», le mot
                                polonais pour «père» ou «papa» que notre mère nous a appris, et
                                que nous avons transformé de différentes façons dans notre toute
                                petite enfance, avant de nous mettre tous deux à utiliser la forme
                                «eitga» (écrite et prononcée à la romanche)? Notre grand-mère
                                suisse, quant à elle, nous parle encore toujours de notre «tatouche»
                                pour désigner notre père.
                            </p>

                            <h2>5.c Rôle ou position des langues, ou sentiment vis-à-vis des langues</h2>
                            <p>
                                Je n’ai pas tout à fait le même rapport avec chacune de mes langues.
                                Le sentiment que j’ai vis-à-vis d’elles, la position qu’elles ont
                                en moi, diffèrent d’une manière subtile et, il faut le souligner
                                clairement, sans que cela dépende essentiellement de la maîtrise plus
                                ou moins grande que j’en ai.
                            </p>
                            <p>
                                J’ai de la peine à penser en termes de langues premières ou
                                secondes. Ces termes ne correspondent pas à ma situation, ne rendent
                                pas ma perception.
                            </p>
                            <p>
                                Le polonais et le français sont mes deux langues maternelles. Ils sont
                                en moi, ils font partie de mon être.
                            </p>
                            <p>
                                L’italien n’est pas une langue maternelle, mais il n’en fait pas
                                moins partie de moi, sans restriction. Je dirais que, alors que le
                                polonais et le français sont dans ma moelle, l’italien est dans mon
                                cerveau – mais ces langues sont toutes trois aussi affectives ou
                                cérébrales les unes que les autres.
                            </p>
                            <p>
                                Le romanche fait aussi partie de moi, je suis très fortement lié à
                                lui, indépendamment de la maîtrise moins parfaite que j’en ai,
                                indépendamment de certains sentiments ambivalents que j’ai vis-à-vis
                                des Romanches. Il est dans une position intermédiaire entre celle de
                                l’italien d’un côté, du polonais et du français de l’autre. En
                                d’autres termes, il m’est à moitié ce que j’appelle une langue
                                maternelle.
                            </p>
                            <p>
                                L’allemand, enfin, a une position par certains côtés intermédiaire
                                entre celle du romanche et celle de l’italien. C’est une langue que
                                j’ai toujours entendue, fût-ce surtout, dans ma toute petite enfance,
                                à la télévision. C’est une langue familière d’aussi loin que je
                                me souvienne (alors que notre père, lui aussi si profondément lié à
                                l’allemand – et au romanche –, n’a pas eu de forte présence de
                                l’allemand pendant les dix premières années de sa vie), une langue
                                que je pratique activement depuis mon enfance, avec des personnes qui me
                                sont chères, une langue dans laquelle j’ai vu d’innombrables longs
                                métrages, documentaires et discussions – et un débat télévisé me
                                plaît plus en allemand qu’en italien. On pourra en fin de compte le
                                mettre dans la catégorie que l’on voudra, l’allemand fait lui aussi
                                partie de moi, et c’est cela qui importe.
                            </p>
                            <p>
                                Pour les autres langues, le lien est différent, et moins fort, et la
                                connaissance que j’ai d’elles est plus faible, me permet moins
                                d’aisance. Malgré tout, j’ai de la peine à les considérer comme
                                des «langues étrangères».
                            </p>
                            <p>
                                À quelques nuances près, et en tenant compte de ce que mon frère se
                                servirait peut-être d’autres termes et d’autres images, je pense
                                que le tableau ci-dessus vaut également pour lui.
                            </p>

                            <h1>6 Binationalité</h1>
                            <p>
                                Nos parents nous ont élevés dans la conscience et une certaine fierté
                                de nos deux origines – sans nous cacher, bien au contraire, ce qui les
                                dérange en Pologne et en Suisse.
                            </p>
                            <p>
                                Il y a eu une brève période, au début de notre scolarité, où mon
                                frère et moi avons couru un certain risque à propos de la moitié
                                polonaise de nos racines. Il y a en Suisse, et dans notre région en
                                particulier, un nombre relativement important d’immigrés des pays
                                dits d’ex-Yougoslavie dont, pour différentes raisons, sociales et
                                autres, l’intégration ne va pas sans quelque difficulté. À
                                l’école, «Slavo» était une insulte et un terme de mépris. J’ai
                                été très surpris et assez choqué quand des camarades ont commencé
                                à se servir de ce mot, dans ce sens, vis-à-vis de moi. J’ai demandé
                                des explications à mes parents, et entre autres ce que j’avais à
                                voir avec des camarades (yougo)slaves, moi qui n’avais aucune
                                connaissance des régions et langues concernées. Mes parents ont réagi
                                en me donnant des explications différenciées quant à la parenté
                                linguistique, mais aussi l’éloignement entre les langues slaves,
                                quant aux achèvements liés à la Pologne et aux connationaux dont on
                                peut être fiers (Maria Skłodowska-Curie!), et, dans la mesure du
                                possible, quant aux aspects sociaux concernés. Notre mère m’a en
                                outre donné le conseil pratique de répliquer, y compris par
                                l’absurde, et, effectivement, je suis parvenu à arrêter une
                                évolution qui aurait pu avoir quelques conséquences négatives pour
                                mon sentiment de moi-même.
                            </p>
                            <p>
                                Dans l’ensemble, mon frère et moi ne cachons ni ne mettons en avant
                                notre binationalité. Nous nous sentons bien avec chacune de nos
                                origines, ou plutôt avec notre origine composite. De fait, nous sommes
                                Polonais, mais Polonais de l’extérieur. Et quant à la Suisse, nous
                                avons un nom alémanique, des prénoms italiens (que nos parents ont
                                choisis pour qu’ils soient facilement prononçables par nos deux
                                parentés), nous sommes nés en territoire romanche, nous habitons dans
                                les Valli, donc à la fois dans les Grisons et en Suisse italienne, nous
                                faisons partie des privilégiés qui parlent les quatre langues
                                nationales; mais nous ne sommes certainement pas des Romanches, nous ne
                                sommes pas non plus des Romands comme notre père, et nous ne sommes ni
                                des Grisons, ni des Suisses italiens. En fin de compte, nous sommes
                                nous, en sommes satisfaits, et ne nous posons guère de questions à ce
                                sujet.
                            </p>

                            <h1>7 Conclusion</h1>
                            <p>
                                Comme nos parents n’avaient pas fixé d’objectif définitif
                                détaillé, et ont simplement voulu «faire au mieux», la question de
                                savoir dans quelle mesure des objectifs initiaux ont pu être atteint ne
                                se pose pas, et c’est très bien ainsi. Nos parents et nous ne sommes
                                pas des robots programmables.
                            </p>
                            <p>
                                Pour ma part – mais je pense que mon frère partage plus ou moins mon
                                sentiment –, je ne peux cacher, aujourd’hui que je suis adulte, que
                                j’aimerais être plus sûr dans ma langue maternelle polonaise, et
                                que, a posteriori, je regrette un peu que nous n’ayons pas eu, mon
                                frère et moi, (encore) plus de discipline, et notre mère, (encore)
                                plus de patience; et que nous n’ayons pas eu de meilleur matériel à
                                disposition.
                            </p>
                            <p>
                                Pour ce qui est du romanche, même si ce n’est pas une langue très
                                utile dans le monde actuel, je serais heureux de le maîtriser encore
                                mieux, parce que c’est bel et bien une de mes langues, et justement
                                aussi parce que nous ne sommes pas nombreux à le parler.
                            </p>
                            <p>
                                Il est dommage par ailleurs que tous les efforts que mon frère et moi
                                – et notre père – avons faits pour acquérir le portugais n’aient
                                pas porté de meilleurs fruits. Il serait bien que cet échec relatif
                                puisse d’une quelconque façon servir pour aider à créer de
                                meilleures conditions pour des projets comparables au nôtre.
                            </p>
                            <p>
                                Enfin, je regrette parfois de ne pas avoir mieux profité, d’une part,
                                des possibilités de lecture que nous avons eues en français, et,
                                d’autre part, de l’offre de notre père de nous donner une culture
                                générale plus vaste que ce que l’école nous assurait. En théorie,
                                ce regret a des justifications. Mais, en pratique, je me rends compte
                                que ce que nous avons fait tous les quatre ensemble est à peu près ce
                                que nous pouvions faire raisonnablement sans que l’effort constant ne
                                devienne insupportable pour tous. Je n’aurais eu davantage qu’au
                                prix, en toute fin de compte, d’une partie de mon enfance et de mon
                                adolescence. Or, j’ai eu une enfance et une adolescence heureuses,
                                riches, respectées, soutenues, et avec la liberté qui me convenait. La
                                conscience de ce bien dissipe rapidement les quelques accès de regret
                                que je peux avoir.
                            </p>
                            <p>
                                Je ressens par ailleurs fortement mon insuffisance et mon insécurité
                                en matière de style et de capacité à rédiger. Mais ici, c’est le
                                système scolaire grison et tessinois, et suisse en général, qui est
                                en cause (à entendre mon parrain professeur d’université et bien
                                d’autres personnes de différents pays et milieux, à voir les
                                résultats chez mes camarades d’autrefois et d’aujourd’hui, et
                                chez les correspondants que mon frère et moi avons dans différents
                                pays, cette lacune n’est en rien spécifique à la Suisse).
                            </p>
                            <p>
                                En face de ces imperfections, de ces manques tout relatifs – comment
                                espérer obtenir partout le meilleur?! –, les achèvements sont,
                                maintenant que j’y pense plus précisément pour ce témoignage, assez
                                impressionnants.
                            </p>
                            <p>
                                À côté de la maîtrise de l’italien que l’école m’a assurée,
                                et malgré la tendance réductrice que le système scolaire a en
                                matière de diversité et richesse linguistiques, contre son effet de
                                «monolinguisation» des jeunes bilingues ou multilingues qui passent
                                par lui, j’ai une maîtrise du français égale à celle de
                                l’italien, égale, donc, à celle que les autres francophones ont
                                atteinte après treize ou quinze ans d’école française (elle aussi
                                réductrice et «monolinguisante»). Je possède le romanche un peu
                                comme un fils de Romanches qui n’aurait eu que peu d’école
                                romanche. J’ai une maîtrise de l’allemand bien supérieure à celle
                                que l’école a assurée à mes anciens camarades, un sentiment de la
                                langue sur la base duquel je pourrai(s) arriver pratiquement au niveau
                                de «Muttersprachler» que l’école italienne m’a attribué de
                                façon exagérée. J’ai en castillan une base qui ne demande elle
                                aussi qu’à se développer. J’ai la base d’anglais que le gymnase
                                m’a assurée. Et, même si je ressens des manques parce que c’est
                                là ma langue maternelle au sens précis du mot, j’ai en polonais une
                                maîtrise naturelle et différenciée, à l’écrit comme à l’oral,
                                telle que, à moins d’être un génie, un non polonophone étudiant le
                                polonais n’atteindra pas de toute sa vie. Et je suis un de ceux qui
                                parlent de manière naturelle ce romanche qui, même réduit à ce
                                qu’il est, est ce que la Suisse a de plus particulier dans le domaine
                                linguistique.
                            </p>
                            <p>
                                Par mes différentes langues, j’ai, enfin, des «chez-moi» dans les
                                trois principales familles de langues européennes.
                            </p>
                            <p>
                                Dans l’ensemble, avec un léger moins en allemand et peut-être en
                                romanche, mais un grand plus en castillan, ce tableau vaut aussi pour
                                mon frère.
                            </p>
                            <p>
                                En d’autres termes, là où l’école grisonne, puis tessinoise, nous
                                aurait assuré, à mon frère et à moi, une langue première et vaille
                                que vaille des bases de, respectivement, trois et deux langues
                                étrangères, en nous laissant des ruines de nos deux langues
                                maternelles, nous avons (ou en tout cas j’ai en ce qui me concerne)
                                cinq «chez-nous» linguistiques, auxquels s’ajoutent ce que
                                j’appellerai pour simplifier trois langues étrangères.
                            </p>
                            <p>
                                Je crois que, au vu de ce résultat, le projet formulé par nos parents
                                s’est réalisé. Et il est d’autant plus un succès qu’il s’est
                                réalisé par un effort qui n’a absolument pas été excessif ou
                                insupportable, un effort qui, sans rien nous prendre de notre enfance et
                                de notre adolescence, a été si constant et discipliné qu’il en
                                était naturel, naturel au point, justement, que ce n’est que
                                maintenant, en rédigeant ce témoignage, que j’en ai pris toute la
                                mesure, avec un certain étonnement.
                            </p>
                            <p>
                                Ce succès, et la manière dont il a été obtenu, donne également la
                                démonstration que nos parents voulaient apporter: même en travaillant
                                en amateur, on peut combattre les effets réducteurs du système
                                scolaire en matière de bilinguisme et de plurilinguisme, et,
                                corollaire, la société peut et doit s’arranger pour élaborer un
                                système scolaire qui ne commence pas par négliger et ruiner les
                                bilinguismes ou plurilinguismes individuels avant d’essayer de
                                construire, de manière pitoyablement inefficace, une maîtrise
                                acceptable en seulement deux langues qualifiées d’étrangères,
                                choisies dans un éventail croupion qui comprend toujours l’anglais.
                            </p>

                        </article>
                    </section>
                </div>
            </div>
            <UpArrow />
            <Footer />
        </div>
    )
}

export default FurerG;