import upArrow from '../images/layout/up-arrow.png';

function UpArrow() {
    return (
        <div align="right">
            <a href="#index"><img src={upArrow} alt="Up Arrow" /></a>
        </div>
    )
}

export default UpArrow;